/* VARIÁVEIS */
:root {
    --text-color: #fff;
    --bg-color: #fff;
    --text-color-alt: #000;
    --grey: #808080;
    --light-grey: rgb(186, 182, 188);
    --dark-grey: #333;
    --dark-grey-blue: #203656;
    --text-color-4: #211f1f;
    --preview-text: #fff;
    --logo-color-light: #03baff;
    --logo-color-dark: #006eff;
    --blue-color-dark: #035397;
    --red-color-dark: #cc6164;
    --alert-red-color-dark: #DA1414;
    --alert-red-color-light: #F48989;
    --alert-green-color-dark: #287D3C;
    --alert-green-color-light: #5ACA75;
    --alert-blue-color-dark: #2E5AAC;
    --alert-blue-color-light: #89A7E0;
    --alert-orange-color-dark: #B95000;
    --alert-orange-color-light: #FF8F39;
}

/* LIMPAR FORMATAÇÃO PADRÃO */
* {
    margin: 0;
    padding: 0;
    border: none;
    box-sizing: border-box;
    font-family: sans-serif;
}

.bg-red {
    background-color: red !important;
}

/* ESTILO SCROlLBAR */
::-webkit-scrollbar {
    width: 1px;
    height: 1px;
}

::-webkit-scrollbar:hover {
    width: 8px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #263238;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #e9e9e9;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #ccc;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:active {
    background: #999999;
}

.App {
    transition: .5s ease;
}

.App[data-sidebar=true] {
    margin-right: 30vw;
}

body[data-overlay=true] {
    overflow: hidden;
}

html,
body {
    overflow-x: hidden;
    scroll-behavior: smooth;
}

input {
    border: none;
}

input:focus {
    outline: none;
}

.link,
a {
    color: var(--logo-color-dark);
    cursor: pointer;
    text-decoration: none;
}

.small {
    font-size: .75rem;
}

.container__content {
    padding: 1.563rem 1rem 1.563rem 1rem;
}

/* BALAO INFORMACOES ADICIONAIS E AJUDA */
.icon-info-add,
.icon-help-add {
    color: var(--logo-color-dark);
}

/* BORDAS PERSONALIZADAS */
.b-bot::after {
    content: "";
    display: block;
    height: 1px;
    margin: 20px auto;
    width: 90%;
    background: #ebebeb;
    background: linear-gradient(90deg, rgba(235, 235, 235, 0) 0%, rgba(235, 235, 235, 1) 50%, rgba(235, 235, 235, 0) 100%);
    /* border-bottom: 1px solid rgba(28,110,164,0.2); */
}

.b-top::before {
    content: "";
    display: block;
    height: 1px;
    margin: 20px auto;
    width: 90%;
    background: #ebebeb;
    background: linear-gradient(90deg, rgba(235, 235, 235, 0) 0%, rgba(235, 235, 235, 1) 50%, rgba(235, 235, 235, 0) 100%);
    /* border-bottom: 1px solid rgba(28,110,164,0.2); */
}

/* BORDAS PERSONALIZADAS -  MÉDIAS */
.b-bot-medium::after {
    content: "";
    display: block;
    height: 1px;
    margin: 10px auto;
    width: 90%;
    background: #ebebeb;
    background: linear-gradient(90deg, rgba(235, 235, 235, 0) 0%, rgba(235, 235, 235, 1) 50%, rgba(235, 235, 235, 0) 100%);
    /* border-bottom: 1px solid rgba(28,110,164,0.2); */
}

.b-top-medium::before {
    content: "";
    display: block;
    height: 1px;
    margin: 10px auto;
    width: 90%;
    background: #ebebeb;
    background: linear-gradient(90deg, rgba(235, 235, 235, 0) 0%, rgba(235, 235, 235, 1) 50%, rgba(235, 235, 235, 0) 100%);
    /* border-bottom: 1px solid rgba(28,110,164,0.2); */
}

/* BORDAS PERSONALIZADAS - PEQUENAS */
.b-bot-small::after {
    content: "";
    display: block;
    height: 1px;
    margin: 5px auto;
    width: 90%;
    background: #ebebeb;
    background: linear-gradient(90deg, rgba(235, 235, 235, 0) 0%, rgba(235, 235, 235, 1) 50%, rgba(235, 235, 235, 0) 100%);
    /* border-bottom: 1px solid rgba(28,110,164,0.2); */
}

.b-top-small::before {
    content: "";
    display: block;
    height: 1px;
    margin: 5px auto;
    width: 90%;
    background: #ebebeb;
    background: linear-gradient(90deg, rgba(235, 235, 235, 0) 0%, rgba(235, 235, 235, 1) 50%, rgba(235, 235, 235, 0) 100%);
    /* border-bottom: 1px solid rgba(28,110,164,0.2); */
}

/* NOTIFICAÇÃO */
.notification {
    background-color: #f8f8f8;
    border-radius: .25rem;
    border: 1px solid #666;
    color: #333;
    padding: .75rem 2rem .75rem .6rem;
    position: relative;
    transition: opacity .3s ease-in-out;
}

.notification-warning {
    background-color: #fffccf;
    border-color: #fae800;
    color: #333;
}

/* BOOT CSS */
.clear {
    clear: both;
}

.left {
    float: left;
}

.right {
    float: right;
}

/* DISPLAY */
.d-nn,
.hidden {
    display: none;
}

.d-fx {
    display: flex;
}

.d-gd {
    display: grid;
}

.d-bk {
    display: block;
}

.d-in-bk {
    display: inline-block;
}

.d-in-fx {
    display: inline-flex;
}

.d-fw-wp {
    flex-wrap: wrap;
}

.d-fd-col {
    flex-direction: column;
}

.d-fd-row {
    flex-direction: row;
}

/* ALINHAMENTO - GERAL*/
.center {
    width: 100%;
    max-width: 1180px;
    margin: auto;
}

/* BORDA COM AFTER */
.destaque__title::after {
    content: ' ';
    position: absolute;
    width: 75%;
    height: 2px;
    background-color: #000;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    margin: -5px 0;
}

/* FORMATAÇÃO - TEXTO*/
.tx-norm {
    font-weight: normal;
}

.tx-bold {
    font-weight: bold;
}

.tx-lig {
    font-weight: lighter;
}

/* CORES - TEXTO*/
.tx-black {
    color: var(--text-color-4);
}

.tx-white {
    color: var(--text-color);
}

.tx-grey {
    color: var(--grey);
}

.tx-grey-light {
    color: var(--light-grey);
}

.tx-grey-dark {
    color: var(--dark-grey);
}

.tx-grey-blue {
    color: var(--dark-grey-blue);
}

.tx-blue-dark {
    color: var(--logo-color-dark);
}

.tx-blue-light {
    color: var(--logo-color-light);
}

.tx-red-dark {
    color: var(--alert-red-color-dark);
}

.tx-red-light {
    color: var(--alert-red-color-light);
}

.tx-green-dark {
    color: var(--alert-green-color-dark);
}

.tx-green-light {
    color: var(--alert-green-color-light);
}

/* ALINHAMENTO - TEXTO*/
.tx-cen {
    text-align: center;
}

.tx-lef {
    text-align: left;
}

.tx-rig {
    text-align: right;
}

/* EFEITOS HOVER - TEXTO */
.tx-hov-dec-line:hover {
    text-decoration: underline;
}

/* POSIÇÕES */
.p-rlt {
    position: relative;
}

.p-abs {
    position: absolute;
}

.p-fix {
    position: fixed;
}

/* CAMADAS (Z-INDEX) */
.z-min,
.z-less {
    z-index: 0 !important;
}

/* .z-max{
    z-index: 9999;
} */

/* ALINHAMENTO - DISPLAY */
.d-al-cen {
    align-items: center;
}

.d-al-lef {
    align-items: flex-start;
}

.d-al-rig {
    align-items: flex-end;
}

.d-al-ct-sp-bt {
    align-content: space-between;
}

.d-ju-cen {
    justify-content: center;
}

.d-ju-lef {
    justify-content: flex-start;
}

.d-ju-rig {
    justify-content: flex-end;
}

.d-ju-sp-bt {
    justify-content: space-between;
}

.d-ju-sp-ar {
    justify-content: space-around;
}

.d-ju-sp-ev {
    justify-content: space-evenly;
}

/* ALTURA / LARGURA */
.w5 {
    width: 5%;
}

.w10 {
    width: 10%;
}

.w15 {
    width: 15%;
}

.w20 {
    width: 16%;
    margin: 0 2%;
}

.w20-c {
    width: 20%;
}

.w25 {
    width: 25%;
}

.w30 {
    width: 30%;
}

.w35 {
    width: 35%;
}

.w40 {
    width: 40%;
}

.w45 {
    width: 45%;
}

.w50 {
    width: 46%;
    margin: auto 2%;
}

.w50-abs,
.w50-c {
    width: 50%;
}

.w55 {
    width: 55%;
}

.w60 {
    width: 60%;
}

.w65 {
    width: 65%;
}

.w70 {
    width: 70%;
}

.w75 {
    width: 75%;
}

.w80 {
    width: 80%;
}

.w85 {
    width: 85%;
}

.w90 {
    width: 90%;
}

.w95 {
    width: 95%;
}

.w100 {
    width: 100%;
}

/* FONTES - TAMANHOS */
.fz-10 {
    font-size: 10px;
}

.fz-11 {
    font-size: 11px;
}

.fz-12 {
    font-size: 12px;
}

.fz-13 {
    font-size: 13px !important;
}

.fz-14 {
    font-size: 14px;
}

.fz-15 {
    font-size: 15px !important;
}

.fz-16 {
    font-size: 16px;
}

.fz-17 {
    font-size: 17px;
}

.fz-18 {
    font-size: 18px;
}

.fz-19 {
    font-size: 19px;
}

.fz-20 {
    font-size: 20px;
}

.fz-21 {
    font-size: 21px;
}

.fz-22 {
    font-size: 22px;
}

.fz-23 {
    font-size: 23px;
}

.fz-24 {
    font-size: 24px;
}

.fz-25 {
    font-size: 25px;
}

.fz-30 {
    font-size: 30px;
}

.fz-35 {
    font-size: 35px;
}

.fz-40 {
    font-size: 40px;
}

.fz-50 {
    font-size: 50px;
}

.fz-60 {
    font-size: 60px;
}

.fz-70 {
    font-size: 70px;
}

.fz-80 {
    font-size: 80px;
}

.fz-90 {
    font-size: 90px;
}

.fz-100 {
    font-size: 100px;
}

/* MARGENS */
/* MARGEM - GERAL */
.margin-top {
    margin-top: 1rem;
}

.margin-bottom {
    margin-bottom: 1rem;
}

.m-g-0 {
    margin: 0 !important;
}

.m-g-5 {
    margin: 5px;
}

.m-g-10 {
    margin: 10px;
}

.m-g-15 {
    margin: 15px;
}

.m-g-20 {
    margin: 20px;
}

.m-g-25 {
    margin: 25px;
}

.m-g-30 {
    margin: 30px;
}

.m-g-35 {
    margin: 35px;
}

.m-g-40 {
    margin: 40px;
}

.m-g-45 {
    margin: 45px;
}

.m-g-50 {
    margin: 50px;
}

.m-g-100 {
    margin: 100px;
}

/* MARGEM - DIREITA */
.m-rig-0 {
    margin-right: 0;
}

.m-rig-5 {
    margin-right: 5px;
}

.m-rig-10 {
    margin-right: 10px;
}

.m-rig-15 {
    margin-right: 15px;
}

.m-rig-20 {
    margin-right: 20px;
}

.m-rig-25 {
    margin-right: 25px;
}

.m-rig-30 {
    margin-right: 30px;
}

.m-rig-35 {
    margin-right: 35px;
}

.m-rig-40 {
    margin-right: 40px;
}

.m-rig-45 {
    margin-right: 45px;
}

.m-rig-50 {
    margin-right: 50px;
}

.m-rig-100 {
    margin-right: 100px;
}

/* MARGEM - ESQUERDA */
.m-lef-0 {
    margin-left: 0 !important;
}

.m-lef-5 {
    margin-left: 5px;
}

.m-lef-10 {
    margin-left: 10px;
}

.m-lef-15 {
    margin-left: 15px;
}

.m-lef-20 {
    margin-left: 20px;
}

.m-lef-25 {
    margin-left: 25px;
}

.m-lef-30 {
    margin-left: 30px;
}

.m-lef-35 {
    margin-left: 35px;
}

.m-lef-40 {
    margin-left: 40px;
}

.m-lef-45 {
    margin-left: 45px;
}

.m-lef-50 {
    margin-left: 50px;
}

.m-lef-100 {
    margin-left: 100px;
}

/* MARGEM - SUPERIOR */
.m-top-0 {
    margin-top: 0px;
}

.m-top-2 {
    margin-top: 2%;
}


.m-top-5 {
    margin-top: 5px;
}

.m-top-10 {
    margin-top: 10px !important;
}

.m-top-15 {
    margin-top: 15px;
}

.m-top-20 {
    margin-top: 20px;
}

.m-top-25 {
    margin-top: 25px;
}

.m-top-30 {
    margin-top: 30px;
}

.m-top-35 {
    margin-top: 35px;
}

.m-top-40 {
    margin-top: 40px;
}

.m-top-45 {
    margin-top: 45px;
}

.m-top-50 {
    margin-top: 50px;
}

.m-top-60 {
    margin-top: 60px;
}

.m-top-100 {
    margin-top: 100px;
}

/* MARGEM - INFERIOR */
.m-bot-0 {
    margin-bottom: 0px;
}

.m-bot-5 {
    margin-bottom: 5px;
}

.m-bot-10 {
    margin-bottom: 10px;
}

.m-bot-15 {
    margin-bottom: 15px;
}

.m-bot-20 {
    margin-bottom: 20px;
}

.m-bot-25 {
    margin-bottom: 25px;
}

.m-bot-30 {
    margin-bottom: 30px;
}

.m-bot-35 {
    margin-bottom: 35px;
}

.m-bot-40 {
    margin-bottom: 40px;
}

.m-bot-45 {
    margin-bottom: 45px;
}

.m-bot-50 {
    margin-bottom: 50px;
}

.m-bot-100 {
    margin-bottom: 100px;
}


/* PADDING */
/* PADDING - GERAL */
.p-g-5 {
    padding: 5px;
}

.p-g-10 {
    padding: .625rem !important;
}

/* .p-g-10{
    padding: 10px !important;
} */
.p-g-15 {
    padding: 15px;
}

.p-g-20 {
    padding: 20px;
}

.p-g-25 {
    padding: 25px;
}

.p-g-30 {
    padding: 30px;
}

.p-g-35 {
    padding: 35px;
}

.p-g-40 {
    padding: 40px;
}

.p-g-45 {
    padding: 45px;
}

.p-g-50 {
    padding: 50px;
}

.p-g-1rem {
    padding: 1rem;
}

/* PADDING - DIREITA */
.p-rig-5 {
    padding-right: 5px;
}

.p-rig-10 {
    padding-right: .625rem;
}

.p-rig-15 {
    padding-right: 15px;
}

.p-rig-20 {
    padding-right: 20px !important;
}

.p-rig-25 {
    padding-right: 25px;
}

.p-rig-30 {
    padding-right: 30px;
}

.p-rig-35 {
    padding-right: 35px;
}

.p-rig-40 {
    padding-right: 40px;
}

.p-rig-45 {
    padding-right: 45px;
}

.p-rig-50 {
    padding-right: 50px;
}

.p-rig-1rem {
    padding-right: 1rem;
}

/* PADDING - ESQUERDA */
.p-lef-5 {
    padding-left: 5px;
}

.p-lef-10 {
    padding-left: .625rem;
}

.p-lef-15 {
    padding-left: 15px;
}

.p-lef-20 {
    padding-left: 20px !important;
}

.p-lef-25 {
    padding-left: 25px;
}

.p-lef-30 {
    padding-left: 30px;
}

.p-lef-35 {
    padding-left: 35px;
}

.p-lef-40 {
    padding-left: 40px;
}

.p-lef-45 {
    padding-left: 45px;
}

.p-lef-50 {
    padding-left: 50px;
}

.p-lef-1rem {
    padding-left: 1rem;
}

/* PADDING - SUPERIOR */
.p-top-0 {
    padding-top: 0px !important;
}

.p-top-5 {
    padding-top: 5px;
}

.p-top-10 {
    padding-top: 10px !important;
}

.p-top-15 {
    padding-top: 15px;
}

.p-top-20 {
    padding-top: 20px;
}

.p-top-25 {
    padding-top: 25px;
}

.p-top-30 {
    padding-top: 30px;
}

.p-top-35 {
    padding-top: 35px;
}

.p-top-40 {
    padding-top: 40px;
}

.p-top-45 {
    padding-top: 45px;
}

.p-top-50 {
    padding-top: 50px;
}

/* PADDING - INFERIOR */
.p-bot-0 {
    padding-bottom: 0px !important;
}

.p-bot-5 {
    padding-bottom: 5px;
}

.p-bot-10 {
    padding-bottom: 10px !important;
}

.p-bot-15 {
    padding-bottom: 15px;
}

.p-bot-20 {
    padding-bottom: 20px;
}

.p-bot-25 {
    padding-bottom: 25px;
}

.p-bot-30 {
    padding-bottom: 30px;
}

.p-bot-35 {
    padding-bottom: 35px;
}

.p-bot-40 {
    padding-bottom: 40px;
}

.p-bot-45 {
    padding-bottom: 45px;
}

.p-bot-50 {
    padding-bottom: 50px;
}

.p-bot-60 {
    padding-bottom: 60px;
}

.p-bot-70 {
    padding-bottom: 70px;
}

.p-bot-80 {
    padding-bottom: 80px;
}

.p-bot-90 {
    padding-bottom: 90px;
}

.p-bot-100 {
    padding-bottom: 100px;
}

/* TAGS E BADGE */
.med-badge {
    display: inline-block;
    padding: calc(1px) 8px calc(2px);
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
    text-align: center;
    user-select: none;
    border-radius: 12px;
    transition: background 200ms ease 0s;
    color: rgb(85, 79, 82);
    background-color: rgb(211, 210, 214);
}

.active-badge {
    color: #006eff;
    background-color: #03bcff3f;
}

.active-badge-success {
    color: rgb(10, 96, 27);
    background-color: rgb(203, 227, 181);
}

.active-badge-error {
    color: rgb(96, 10, 10);
    background-color: rgb(227, 181, 181);
}

/* INPUTS - TEXTAREAS */
/* INPUT - PADRÃO */
input.default {
    border: 1px solid transparent;
}

input.default::placeholder,
textarea::placeholder  {
    color: #ddd;
}

input:disabled {
    cursor: not-allowed;
}

/* INPUT - ERRO */
input.error {
    border: 1px solid var(--alert-red-color-dark);
}

input.error::placeholder {
    color: var(--alert-red-color-light);
}

/* INPUT - SUCESSO */
input.success {
    border: 1px solid var(--alert-green-color-dark);
}

input.success::placeholder {
    color: var(--alert-green-color-light);
}

/* INPUT - PERSONALIZADO */

@supports (-webkit-appearance: none) or (-moz-appearance: none) {

    input[type=checkbox],
    input[type=radio] {
        --active: #275EFE;
        --active-inner: #fff;
        --focus: 2px rgba(39, 94, 254, .3);
        --border: #BBC1E1;
        --border-hover: #275EFE;
        --background: #fff;
        --disabled: #F6F8FF;
        --disabled-inner: #E1E6F9;
        -webkit-appearance: none;
        -moz-appearance: none;
        height: 21px;
        outline: none;
        display: inline-block;
        vertical-align: top;
        position: relative;
        margin: 0;
        cursor: pointer;
        border: 1px solid var(--bc, var(--border));
        background: var(--b, var(--background));
        transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
    }

    input[type=checkbox]:after,
    input[type=radio]:after {
        content: "";
        display: block;
        left: 0;
        top: 0;
        position: absolute;
        transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
    }

    input[type=checkbox]:checked,
    input[type=radio]:checked {
        --b: var(--active);
        --bc: var(--active);
        --d-o: .3s;
        --d-t: .6s;
        --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
    }

    input[type=checkbox]:disabled,
    input[type=radio]:disabled {
        --b: var(--disabled);
        cursor: not-allowed;
        opacity: 0.9;
    }

    input[type=checkbox]:disabled:checked,
    input[type=radio]:disabled:checked {
        --b: var(--disabled-inner);
        --bc: var(--border);
    }

    input[type=checkbox]:disabled+label,
    input[type=radio]:disabled+label {
        cursor: not-allowed;
    }

    input[type=checkbox]:hover:not(:checked):not(:disabled),
    input[type=radio]:hover:not(:checked):not(:disabled) {
        --bc: var(--border-hover);
    }

    input[type=checkbox]:focus,
    input[type=radio]:focus {
        box-shadow: 0 0 0 var(--focus);
    }

    input[type=checkbox]:not(.switch),
    input[type=radio]:not(.switch) {
        width: 21px;
        min-width: 21px;
    }

    input[type=checkbox]:not(.switch):after,
    input[type=radio]:not(.switch):after {
        opacity: var(--o, 0);
    }

    input[type=checkbox]:not(.switch):checked,
    input[type=radio]:not(.switch):checked {
        --o: 1;
    }

    input[type=checkbox]+label,
    input[type=radio]+label {
        font-size: 14px;
        line-height: 21px;
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
        margin-left: 4px;
    }

    input[type=checkbox]:not(.switch) {
        border-radius: 7px;
    }

    input[type=checkbox]:not(.switch):after {
        width: 5px;
        height: 9px;
        border: 2px solid var(--active-inner);
        border-top: 0;
        border-left: 0;
        left: 7px;
        /* top: 4px; */
        top: 2px;
        transform: rotate(var(--r, 20deg));
    }

    input[type=checkbox]:not(.switch):checked {
        --r: 43deg;
    }

    input[type=checkbox].switch {
        width: 38px;
        border-radius: 11px;
    }

    input[type=checkbox].switch:after {
        left: 2px;
        top: 2px;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        background: var(--ab, var(--border));
        transform: translateX(var(--x, 0));
    }

    input[type=checkbox].switch:checked {
        --ab: var(--active-inner);
        --x: 17px;
    }

    input[type=checkbox].switch:disabled:not(:checked):after {
        opacity: 0.6;
    }

    input[type=radio] {
        border-radius: 50%;
    }

    input[type=radio]:after {
        width: 19px;
        height: 19px;
        border-radius: 50%;
        background: var(--active-inner);
        opacity: 0;
        transform: scale(var(--s, 0.7));
    }

    input[type=radio]:checked {
        --s: .5;
    }
}

/* INPUT - PERSONALIZADO 2 -  d-fx d-al-cen m-top-5 m-bot-5 m-rig-0 p-rlt radio-item w100*/

.form-icons {
    display: flex;
    align-items: center;
    padding: 10px;
    /* width: 100%;
    height: 50px; */
    border-radius: 5px;
    border: 1px solid #ccc;
}

.form-icons i,
.form-icons i {
    font-size: 25px;
    padding-right: 10px;
}

input:focus,
textarea:focus {
    outline: none;
}

.form-icons input[type=text],
.form-icons input[type=email],
.form-icons input[type=number] {
    width: 100%;
    height: 30px;
    font-size: 13px;
    color: #404040;
    padding-left: 5px;
    border: none;
    border-left: 2px solid #ccc;
}

.form-icons textarea {
    width: 100%;
    min-width: 95%;
    max-width: 95%;
    height: 150px;
    min-height: 150px;
    font-size: 15px;
    color: #404040;
    border: none;
    padding-left: 5px;
    border-left: 2px solid #ccc;
}

/* BOOT - TOOLTIPS */
:root {
    --cooltipz-fontawesome: FontAwesome;
}

.custom--small {
    --cooltipz-small: 4.5rem;
}

.custom--medium {
    --cooltipz-medium: 8rem;
}

.custom--large {
    --cooltipz-large: 12rem;
}

.custom--revert {
    --cooltipz-bg-color: #fff;
    --cooltipz-text-color: #000;
    --cooltipz-border-color: #fff;
}

.custom--help {
    --cooltipz-cursor: help;
}

.custom--delay {
    --cooltipz-delay-show: 1s;
    --cooltipz-delay-hide: 2s;
}

.custom--far {
    --cooltipz-slide: 50px;
}

.custom--still {
    --cooltipz-slide: 0;
}

.custom--opposite {
    --cooltipz-slide: -50px;
}

.custom--big {
    --cooltipz-font-size: 1.6rem;
    --cooltipz-arrow-size: 1rem;
}

.custom--arrow {
    --cooltipz-arrow-size: 1.5rem;
    --cooltipz-border-radius: 0;
}

.custom--round {
    --cooltipz-border-radius: 5rem;
}

.custom--sharp {
    --cooltipz-border-radius: 0;
}

.custom--pointy {
    --cooltipz-arrow-size: 0.82rem;
    --cooltipz-border-radius: 0;
}

.custom--slow {
    --cooltipz-timing: 3s;
}

.custom--border {
    --cooltipz-border-color: #f00;
    --cooltipz-border-width: 2px;
}

.custom--border-2 {
    --cooltipz-border-color: #0f0;
    --cooltipz-border-width: 2px;
    --cooltipz-border-style: dashed;
}

.custom--border-3 {
    --cooltipz-border-color: #000;
    --cooltipz-border-width: 2px;
    --cooltipz-text-color: #000;
    --cooltipz-bg-color: #fff;
}

/* TOOLTIPS FORMULÁRIOS */
.box-input-single .title-input .icon-info-add:hover .helper-input {
    display: block !important;

    pointer-events: none;
    z-index: 9999;
    position: absolute;
    inset: -15px auto auto 30px;
    margin: 0px;
    min-width: 235px;
}

.css-1pccujx-popover {
    position: relative;
    font-size: 13px;
    line-height: 20px;
    box-shadow: rgba(60, 55, 56, 0.07) 0px 0px 0px 1px, rgba(60, 55, 56, 0.07) 0px 2px 2px 0px, rgba(60, 55, 56, 0.07) 0px 4px 4px 0px;
    max-width: 250px;
    padding: 16px;
    background: rgb(255, 255, 255);
    border-radius: 8px;
    transition-property: transform, visibility, opacity;
}

.css-1i1x8nz {
    display: block;
    margin-bottom: 8px;
    font-weight: 600;
}

.css-1l7kih-stack {
    display: flex;
    flex-direction: column;
}

.css-1l7kih-stack>div:not(:last-child) {
    margin-bottom: 8px;
}

.css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center {
    display: flex;
    flex-direction: row;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
}

.css-1lmzrv3-inline-inline-alignX--flex-start-inline-alignY--center>div:not(:last-child) {
    margin-right: 8px;
}

.css-1dwd66e-text-text--kilo-heading--no-margin {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 0px;
}

.css-1pccujx-popover[data-placement^="right"]>[data-popper-arrow] {
    left: -6px;
}

.css-tlli5z,
.css-tlli5z::after {
    position: absolute;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
}

.css-1pccujx-popover[data-placement^="right"]>[data-popper-arrow]::after {
    border-bottom-left-radius: 4px;
    box-shadow: rgba(60, 55, 56, 0.07) -1px 1px 0px 0px, rgba(60, 55, 56, 0.07) -1px 1px 0px 0px;
}

.css-tlli5z::after {
    content: "";
    background: rgb(255, 255, 255);
}

.css-tlli5z,
.css-tlli5z::after {
    position: absolute;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
}

/* BOOT - FILTRO - PREÇO */
.price-input {
    width: 100%;
    display: flex;
}

.price-input .field {
    display: flex;
    width: 100%;
    /* height: 45px; */
    align-items: center;
}

.field input {
    width: 100%;
    height: 100%;
    outline: none;
    font-size: 16px;
    padding: .625rem 0;
    /* margin-left: 12px; */
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
    text-align: center;
    border: 1px solid #999;
    -moz-appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.price-input .separator {
    width: 130px;
    display: flex;
    font-size: 19px;
    align-items: center;
    justify-content: center;
}

.slider {
    /* height: 5px; */
    position: relative;
    background: #ddd;
    border-radius: 5px;
}

.slider .progress {
    height: 100%;
    left: 25%;
    right: 25%;
    position: absolute;
    border-radius: 5px;
    background: #17A2B8;
}

.range-input {
    position: relative;
}

.range-input input {
    position: absolute;
    width: 100%;
    height: 5px;
    left: 0;
    top: -5px;
    background: none;
    pointer-events: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

input[type="range"]::-webkit-slider-thumb {
    height: 17px;
    width: 17px;
    border-radius: 50%;
    background: #17A2B8;
    pointer-events: auto;
    -webkit-appearance: none;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
}

input[type="range"]::-moz-range-thumb {
    height: 17px;
    width: 17px;
    border: none;
    border-radius: 50%;
    background: #17A2B8;
    pointer-events: auto;
    -moz-appearance: none;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
}

/* BOOT - INPUT - SINGUP */


/* BOOT - INPUT - LOGIN */
.login-divider {
    margin-bottom: 1rem;
    margin-top: 1rem;
    position: relative;
    text-align: center;
}

.login-divider:after {
    background-color: #a6a6a6;
    content: " ";
    height: .0625rem;
    left: 0;
    position: absolute;
    top: .5625rem;
    width: 100%;
    z-index: 1;
}

.login-divider span {
    background-color: #fff;
    padding-left: .625rem;
    padding-right: .625rem;
    position: relative;
    z-index: 2;
}

.input:focus {
    border-color: #fff #fff #397615;
}

.field .input:focus+.label,
.field .select:focus+.label,
.field .textarea:focus+.label {
    font-size: .8rem;
    transform: translateY(-130%);
}

.field {
    /* margin-top: 1.5rem; */
    padding-top: .5rem;
    position: relative;
}

.input {
    border: .0625rem solid #a6a6a6;
    border-radius: .25rem;
    color: #333;
    outline: 0;
    padding: .6rem .4rem;
    transition: border-color .3s ease-in-out;
    width: 100%;
}

.field .input+.label,
.field .select+.label,
.field .textarea+.label {
    left: .45rem;
    position: absolute;
    top: 1rem;
}

.field .label {
    transition: all .2s ease-in-out;
}

.label {
    color: #666;
    cursor: text;
    font-size: .9rem;
    line-height: 1.5rem;
}

.field .helper {
    display: block;
    margin-left: .4rem;
    margin-top: .4rem;
}

.helper {
    color: #666;
    font-size: .75rem;
    font-style: italic;
}

.field:after {
    border-radius: 1rem;
    content: " ";
    height: 1.12rem;
    position: absolute;
    right: .12rem;
    top: 1rem;
    width: 1.12rem;
}

.button-full {
    width: 100%;
    border-radius: .25rem;
    display: inline-block;
    font-size: 1rem;
    padding: .68rem .81rem;
    position: relative;
    /* z-index: 10; */
    background-color: #a6a6a6;
    border: 0;
    color: #fff;
    background-color: var(--logo-color-dark);
    cursor: pointer;
    line-height: 1.5rem;
    transition: transform .3s ease-in-out;
}

.button-dafult {
    width: 100%;
    border-radius: .25rem;
    display: inline-block;
    font-size: 1rem;
    padding: .68rem .81rem;
    position: relative;
    /* z-index: 10; */
    background-color: #a6a6a6;
    border: 0;
    color: #554f52;
    background-color: #e9e9e9;
    cursor: pointer;
    line-height: 1.5rem;
    transition: transform .3s ease-in-out;
}

.button-dafult:hover {
    transition:.25s ease-in-out;
    background-color: #ccc;
}

.button:before {
    box-shadow: 0 .12rem .12rem 0 rgba(0, 0, 0, .16), 0 .12rem .5rem 0 rgba(0, 0, 0, .12);
}

.button:after,
.button:before {
    border-radius: .25rem;
    content: " ";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: opacity .3s ease-in-out;
    width: 100%;
    z-index: -1;
}

.button .glyph {
    float: right;
    font-size: 1.5rem;
    vertical-align: middle;
}

.social-button {
    align-items: center;
    border-radius: .5rem;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-weight: 400;
    justify-content: center;
    line-height: .625rem;
    min-height: 2.8125rem;
    padding: .625rem 0;
    transition: all .2s ease;
    width: 100%;
}

.social-button:before {
    background-size: cover;
    content: "";
    height: 1.125rem;
    margin-right: 1.125rem;
    width: 1.125rem;
}

.social-button.social-facebook {
    background-color: #1877f2;
    color: #fff;
}

.social-button.social-facebook:before {
    background-image: url(./images/icones/svg/facebook.svg);
}

.social-button.social-google {
    background-color: #fff;
    border: .063rem solid #bab6bc;
    color: #333;
    font-weight: 400;
}

.social-button.social-google:before {
    background-image: url(./images/icones/svg/google.svg);
}

.social-button.social-apple {
    background-color: #000;
    color: #fff;
    font-weight: 400;
    text-indent: 1.125rem;
}

.social-button.social-apple:before {
    background-image: url(./images/icones/svg/apple-logo.svg);
}

/* BOOT - COOKIE*/

.box-cookie {
    position: fixed;
    bottom: 30px;
    left: 30px;
    max-width: 365px;
    background: #fff;
    padding: 25px 25px 30px 25px;
    border-radius: 15px;
    box-shadow: 1px 7px 14px -5px rgba(0, 0, 0, 0.15);
    text-align: center;
    z-index: 9999;
}

.box-cookie.hide {
    opacity: 0;
    pointer-events: none;
    transform: scale(0.8);
    transition: all 0.3s ease;
}

.box-cookie img {
    max-width: 90px;
}

.box-cookie .content h2 {
    font-size: 25px;
    font-weight: 600;
    color: var(--text-color-4);
}

.box-cookie .content {
    margin-top: 10px;
}

.box-cookie .content p {
    color: var(--grey);
    margin: 5px 0 20px 0;
}

.box-cookie .content .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.box-cookie .buttons button {
    padding: 10px 20px;
    border: none;
    outline: none;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
    background: #FCBA7F;
    cursor: pointer;
    transition: all 0.3s ease;
}

.box-cookie .buttons button:hover {
    transform: scale(0.97);
}

.box-cookie .buttons .item {
    margin: 0 10px;
}

.box-cookie .buttons a {
    color: #FCBA7F;
}

/* ESTILO - SELECT COM BUSCA */
div.searchable {
    width: 100%;
    float: left;
    margin: 0 auto;
}

.searchable input {
    width: 100%;
    height: 50px;
    font-size: 13px;
    padding: 10px;
    -webkit-box-sizing: border-box;
    /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;
    /* Firefox, other Gecko */
    box-sizing: border-box;
    /* Opera/IE 8+ */
    display: block;
    font-weight: 400;
    line-height: 1.6;
    color: var(--dark-grey-blue);
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right .75rem center/8px 10px;
}

.searchable input:focus {
    color: var(--dark-grey-blue);
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.searchable ul {
    display: none;
    list-style-type: none;
    background-color: #fff;
    border-radius: 0 0 5px 5px;
    border: 1px solid #80bdff;
    border-top: none;
    max-height: 180px;
    margin: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.searchable ul li {
    margin: 0;
    padding: 7px 9px;
    font-size: 15px;
    border-bottom: 1px solid #e1e1e1;
    cursor: pointer;
    color: var(--grey);
    /* width: 100%; */
    text-align: left;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
}

.searchable ul li.selected {
    background-color: #e8e8e8;
    color: #333;
}

.chosen-value,
.value-list {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.chosen-value {
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    /* letter-spacing: 4px; */
    /* height: 4rem; */
    font-size: 15px;
    /* padding: 1rem; */
    padding: 5px;
    background-color: #FAFCFD;
    border: 3px solid transparent;
    transition: 0.3s ease-in-out;
}

.chosen-value::-webkit-input-placeholder {
    color: #333;
}

.chosen-value:hover {
    background-color: #FF908B;
    cursor: pointer;
}

.chosen-value:hover::-webkit-input-placeholder {
    color: #333;
}

.chosen-value:focus,
.chosen-value.open {
    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.2);
    outline: 0;
    background-color: #FF908B;
    color: #000;
}

.chosen-value:focus::-webkit-input-placeholder,
.chosen-value.open::-webkit-input-placeholder {
    color: #000;
}

.value-list {
    list-style: none;
    margin-top: 40px;
    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    max-height: 0;
    transition: 0.3s ease-in-out;
}

.value-list.open {
    max-height: 320px;
    overflow: auto;
}

.value-list li {
    position: relative;
    /* height: 4rem; */
    background-color: #FAFCFD;
    padding: 5px;
    margin: 0;
    font-size: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s;
    opacity: 1;
}

.value-list li:hover {
    background-color: #FF908B;
}

.value-list li.closed {
    max-height: 0;
    overflow: hidden;
    padding: 0;
    opacity: 0;
}

/* FONTES - CORES */
.icon-primary {
    font-size: 25px;
    margin-right: 5px;
}

.info-primary {
    color: var(--logo-color-dark)
}

.info-secondary {
    color: #fff;
}

.info-default {
    color: #a6a6a6;
}

/* BOOT BOTAO */
button:disabled,
.link__disabled  {
    opacity: .3;
    cursor: not-allowed !important;
}

/* ESTILO - BOTAO - MODELO 1 */
.cta {
    position: relative;
    /* margin: auto; */
    padding: 12px 18px;
    transition: all 0.2s ease;
    border: none;
    background: none;
    cursor: pointer;
}

.cta:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-radius: 50px;
    background: #b1dae7;
    width: 45px;
    height: 45px;
    transition: all 0.3s ease;
}

.cta span {
    position: relative;
    font-family: "Ubuntu", sans-serif;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.05em;
    color: #234567;
}

.cta svg {
    position: relative;
    top: 0;
    margin-left: 10px;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: #234567;
    stroke-width: 2;
    transform: translateX(-5px);
    transition: all 0.3s ease;
}

.cta:hover:before {
    width: 100%;
    background: #b1dae7;
}

.cta:hover svg {
    transform: translateX(0);
}

.cta:active {
    transform: scale(0.95);
}

/* ESTILO - BOTAO - MODELO 2 */
.btn-mod-2 {
    font-family: inherit;
    font-size: 18px;
    background: var(--logo-color-dark);
    color: #fff;
    padding: 0.5em .8em;
    padding-left: 0.9em;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 8px;
    overflow: hidden;
    transition: all 0.2s;
    cursor: pointer;
}

.btn-mod-2 span {
    display: block;
    margin-left: 0.3em;
    transition: all 0.3s ease-in-out;
}

.btn-mod-2 svg {
    display: block;
    transform-origin: center center;
    transition: transform 0.3s ease-in-out;
}

.btn-mod-2:hover .svg-wrapper {
    animation: fly-1 0.6s ease-in-out infinite alternate;
}

.btn-mod-2:hover svg {
    transform: translateX(2.2em) rotate(45deg) scale(1.1);
}

.btn-mod-2:hover span {
    transform: translateX(8em);
}

.btn-mod-2:active {
    transform: scale(0.95);
}

@keyframes fly-1 {
    from {
        transform: translateY(0.1em);
    }

    to {
        transform: translateY(-0.1em);
    }
}

/* ESTILO - BOTAO - MODELO 9 - MOSTRAR OU OCULTAR SENHA */
.btn__toggle__state__password {
    background: none;
    top: 55%;
    right: 10px;
    transform: translateY(-45%);
    font-size: 16px;
    color: rgb(34, 32, 32);
    cursor: pointer;
}
/* ESTILO - BOTAO - MODELO 3 */
/* BOTÃO DE CARREGAR MAIS */
.more-proj-load {
    position: relative;
}

.button-load {
    position: relative;
    height: 50px;
    width: 200px;
    /* top: calc(50vh - 25px);
      left: calc(50vw - 100px); */
    /* margin: 30px; */
    background-image: none;
    border: none;
    outline: none;
    background-color: #035397;
    color: white;
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 2px;
    cursor: pointer;
    transition: all 0.2s ease-out;
    border-radius: 8px;
}

.button-load::after {
    content: '';
    display: block;
    position: absolute;
    width: 160px;
    height: 40px;
    background-color: black;
    z-index: -1;
    left: calc(50% - 80px);
    top: 10px;
    opacity: 0.3;
    filter: blur(5px);
    transition: all 0.2s ease-out;
}

.button-load:hover::after {
    opacity: 0.5;
    filter: blur(20px);
    transform: translatey(10px) scalex(1.2);
}

.button-load:active {
    background-color: #133B5C;
}

.button-load:active::after {
    opacity: 0.3;
}

.loading {
    border-radius: 50px;
    width: 50px;
    /* left: calc(50vw - 25px); */
}

.button-load.loading::after {
    width: 40px;
    left: 5px;
    top: 12px;
    border-radius: 100%;
}

.spinner {
    display: block;
    width: 34px;
    height: 34px;
    position: absolute;
    top: 8px;
    left: calc(50% - 17px);
    background: transparent;
    box-sizing: border-box;
    border-top: 4px solid white;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-radius: 100%;
    animation: spin 0.6s ease-out infinite;
}

/* ESTILO - BOTAO - MODELO 4 */
.btn-minimalista {
    color: var(--logo-color-dark);
    cursor: pointer;
}

.btn-minimalista {
    margin: 0 10px;
    padding: 8px;
    border: 1px solid var(--logo-color-dark);
    border-radius: 8px;
    font-size: 13px;
    background: none;
}

.btn-minimalista:hover {
    background-color: #006eff10;
}

.selected {
    background-color: var(--logo-color-dark);
    color: var(--text-color);

    padding: 8px;
    border: 1px solid var(--logo-color-dark);
    border-radius: 8px;
    font-size: 13px;

    cursor: pointer;
}

.selected:hover {
    background-color: #035397;
    border-color: transparent;
}

/* ESTILO - BOTAO - MODELO 5 */
.btn-red {
    border: 1px solid var(--red-color-dark);
    color: var(--red-color-dark);
    background: none;
}

.btn-red:hover {
    background-color: #ff000010;
    border: 1px solid var(--red-color-dark);
}

/* ESTILO - NEWSLETTER - GERAL */
.newsletter .news-content {
    /* background-color: #006DFF;
    overflow: hidden;
    padding: 32px 40px; */
    padding: 32px;
    background-color: rgb(2, 39, 59);
    background-size: 100px;
    background-image: url("https://cdn.leroymerlin.com.br/assets/images/home/newsletter/cart.svg");
    background-repeat: no-repeat;
    background-position: calc(100% + 15px) 10px;
    border-radius: 12px;
    padding: 32px 40px;
    background-position: -15px 10px;
}

.newsletter .news-content .icon-bg-news {
    left: -15px;
    max-width: 100px;
    z-index: 1;
}

.newsletter .news-content .text-news {
    z-index: 1;
    color: #fff;
}

.newsletter .news-content .content-input>input {
    border-radius: 8px;
    padding: 15px;
    font-size: 18px;
}

.newsletter .info-termos {
    color: rgb(186, 182, 188);
    font-style: italic;
    font-size: 12px;
    margin-top: 8px;
}


/* ESTILO - FAQ - GERAL */

/* LINHA E BOTÃO MOSTRAR OU OCULTAR CONTEUDO DESCRIÇÃO DA PÁGINA */
.description-page__btn-toggle {
    text-align: center;
    font-size: .75rem;
}

.line-wrapper {
    display: inline-block;
    position: relative;
    cursor: pointer;
}

.line-wrapper:after,
.line-wrapper:before {
    background-color: #c8c8c8;
    content: "";
    display: inline-block;
    height: .0625rem;
    position: absolute;
    top: 65%;
    transform: translateY(-50%);
    /* width: 6.25rem; */
    width: 45%;
}

.line-wrapper:before {
    left: 0;
}

.line-wrapper::after {
    right: 0;
}

/* BOOT - MODAL */
.modal {
    background-color: hsla(0, 0%, 40%, .5);
    height: 100%;
    width: 100%;
    /* z-index: 9999; */
    z-index: 999;
}

.modal-enter {
    left: 0;
    position: fixed;
    top: 0;
}

.modal:before {
    content: "";
    display: inline-block;
    height: 100%;
    position: relative;
    vertical-align: middle;
}

.modal-content {
    background-color: #fff;
    border-radius: .25rem;
    box-shadow: 0 0 .3rem rgba(0, 0, 0, .5);
    display: none;
    left: 50%;
    margin-left: -47.5%;
    max-height: 90%;
    opacity: 0;
    overflow: auto;
    /* padding: 1rem; */
    padding: 2rem;
    position: relative;
    transform: scale(1.3);
    transition: transform .3s ease, opacity .3s ease;
    vertical-align: middle;
    width: 95%;
}

.modal-content-enter {
    display: inline-block;
}

.modal-content-show {
    opacity: 1;
    transform: scale(1);
}

.modal-content-md {
    margin-left: -25%;
    width: 50%;
}

.container,
.container-fluid {
    zoom: 1;
    margin-left: auto;
    margin-right: auto;
    padding-left: 12px;
    padding-right: 12px;
}

.container-fluid:before,
.container:before {
    content: "";
    display: table;
}

.modal .modal-title {
    color: var(--logo-color-dark);
    font-size: 1.6rem;
}

.modal .row__modal {
    zoom: 1;
    box-sizing: border-box;
    margin-left: -12px;
    margin-right: -12px;
}

.modal .row__modal:after,
.modal .row__modal:before {
    content: "";
    display: table;
}

.col-sm-4 {
    box-sizing: border-box;
    float: left;
    padding-left: 12px;
    padding-right: 12px;
}

.col-sm-4 {
    width: 33.33333%;
}

.field {
    padding-top: .5rem;
    position: relative;
}

.field {
    /* margin-top: 1.5rem; */
}

/* .select {
    border: .0625rem solid #a6a6a6;
    border-radius: .25rem;
    color: #333;
    outline: 0;
    padding: .6rem .4rem;
    transition: border-color .3s ease-in-out;
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    font-size: 1.1rem;
    height: 2.5rem;
} */
.label {
    color: #666;
    cursor: text;
    font-size: .9rem;
    line-height: 1.5rem;
}

.field .label {
    transition: all .2s ease-in-out;
}

.field .input+.label,
.field .select+.label,
.field .textarea+.label {
    left: .45rem;
    position: absolute;
    top: 1rem;
}

.field .select+.label {
    pointer-events: none;
}

.helper {
    color: #666;
    font-size: .75rem;
    font-style: italic;
}

.field .helper {
    display: block;
    margin-left: .4rem;
    margin-top: .4rem;
}

.field:after {
    border-radius: 1rem;
    content: " ";
    height: 1.12rem;
    position: absolute;
    right: .12rem;
    top: 1rem;
    width: 1.12rem;
}

.col-sm-8 {
    box-sizing: border-box;
    float: left;
    padding-left: 12px;
    padding-right: 12px;
}

.col-sm-8 {
    width: 66.66667%;
}

.field {
    padding-top: .5rem;
    position: relative;
}

.field {
    /* margin-top: 1.5rem; */
}

.input {
    border: .0625rem solid #a6a6a6;
    border-radius: .25rem;
    color: #333;
    outline: 0;
    padding: .6rem .4rem;
    transition: border-color .3s ease-in-out;
    width: 100%;
}

.field.invalid .input,
.field.invalid .select,
.field.invalid .textarea {
    border-color: #fff #fff #ed1c24;
}

.label {
    color: #666;
    cursor: text;
    font-size: .9rem;
    line-height: 1.5rem;
}

.field .label {
    transition: all .2s ease-in-out;
}

.field .input+.label,
.field .select+.label,
.field .textarea+.label {
    left: .45rem;
    position: absolute;
    top: 1rem;
}

.field.invalid .helper,
.field.invalid .label {
    color: #ed1c24;
}

.field.invalid .helper,
.field.invalid .label {
    color: #ed1c24;
}

.field.invalid:after {
    content: "\EA75";
    background-color: #ed1c24;
    color: #fff;
    font-family: Glyphs;
    font-size: .6rem;
    line-height: 1.2rem;
    text-align: center;
}

.field:after {
    border-radius: 1rem;
    content: " ";
    height: 1.12rem;
    position: absolute;
    right: .12rem;
    top: 1rem;
    width: 1.12rem;
}

.col-xs-12 {
    box-sizing: border-box;
    float: left;
    padding-left: 12px;
    padding-right: 12px;
}

.field {
    padding-top: .5rem;
    position: relative;
}

.field {
    /* margin-top: 1.5rem; */
}

.col-xs-12 {
    box-sizing: border-box;
    float: left;
    padding-left: 12px;
    padding-right: 12px;
}

.align-right {
    text-align: right;
}

.btn-default {
    border-radius: .25rem;
    display: inline-block;
    font-size: 1rem;
    padding: .68rem .81rem;
    position: relative;
    z-index: 10;
    background-color: #a6a6a6;
    border: 0;
    color: #fff;
    transition: transform .3s ease-in-out;
}

.button-text {
    background-color: transparent;
    color: var(--logo-color-dark);
    padding: 0;
    transition: none;
}

.modal .button-text {
    color: rgba(0, 0, 0, 0.54);
    margin-right: .8rem;
    padding: .68rem .81rem;
    transition: .3s ease-in-out;
}

.modal .button-text:hover {
    background: rgba(0, 0, 0, .06);
}

.btn-default {
    cursor: pointer;
    line-height: 1.5rem;
}

.button-primary {
    background-color: var(--logo-color-dark);
}
.button-primary:hover,
.button-primary:focus {
    transition: .25s ease-in-out;
    background-color: var(--blue-color-dark);
}

.button-primary:after,
.button-primary:before {
    border-radius: .25rem;
    content: " ";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: opacity .3s ease-in-out;
    width: 100%;
    z-index: -1;
}

.button-primary:before {
    box-shadow: 0 .12rem .12rem 0 rgba(0, 0, 0, .16), 0 .12rem .5rem 0 rgba(0, 0, 0, .12);
}

.button-primary:after {
    box-shadow: 0 1.25rem 1.25rem -.62rem rgba(0, 0, 0, .2), 0 .25rem .81rem 0 rgba(0, 0, 0, .2);
    opacity: 0;
}

.modal-close {
    color: var(--logo-color-dark);
    float: right;
    font-size: 1.5rem;
    position: absolute;
    right: 1rem;
    top: 1rem;
    transition: color .3s ease-in-out;
    cursor: pointer;
}


/* BOTÃO FILTRAR PROJETOS - LEROY MERLIN  */
.css-o80aeu-button-button--kilo {
    position: relative;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: auto;
    height: auto;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
    user-select: none;
    border-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-radius: 8px;
    outline: none;
    transition: color 200ms ease 0s, background 200ms ease 0s, border-color 200ms ease 0s, box-shadow 200ms ease 0s;
    color: rgb(65, 160, 23);
    background: transparent;
    font-size: 13px;
    line-height: 20px;
    padding: calc(7px) 8px;
}

.css-mqdqed-share-button-share-button__icon button svg {
    color: rgb(111, 103, 108);
}

.css-o80aeu-button-button--kilo:hover {
    color: rgb(21, 129, 16);
    background: rgb(234, 243, 226);
}

.css-o80aeu-button-button--kilo:active {
    color: rgb(21, 129, 16);
    background: rgb(203, 227, 181);
    border-color: rgb(21, 129, 16);
}

.css-o80aeu-button-button--kilo {
    position: relative;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: auto;
    height: auto;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
    user-select: none;
    border-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-radius: 8px;
    outline: none;
    transition: color 200ms ease 0s, background 200ms ease 0s, border-color 200ms ease 0s, box-shadow 200ms ease 0s;
    color: rgb(65, 160, 23);
    background: transparent;
    font-size: 13px;
    line-height: 20px;
    padding: calc(7px) 8px;

}

.css-o80aeu-button-button--kilo.btn__filter {
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(60, 55, 56, 0.07) 0px 0px 0px 1px, rgba(60, 55, 56, 0.07) 0px 2px 2px 0px, rgba(60, 55, 56, 0.07) 0px 4px 4px 0px;
    border-radius: 8px;
}

.css-o80aeu-button-button--kilo:hover {
    color: rgb(21, 129, 16);
    background: rgb(234, 243, 226);
}

.css-o80aeu-button-button--kilo:active {
    color: rgb(21, 129, 16);
    background: rgb(203, 227, 181);
    border-color: rgb(21, 129, 16);
}


/* MENU KEBAB */
/* OPÇÕES DE GALERIA DE PRODUTOS */
.menu__kebab__visualizacao__galeria {
    right: 0;
    top: 0;
}
.menu__kebaba__content{
    z-index: 1;
    right: 0;
    top: 0;
}
.btn__menu__kebab {
    z-index: 1;
    right: 5px;
    background: rgb(238 238 240);
    border-radius: 50px;
    padding: 6px;
    opacity: 0.7;
    color: rgb(60, 55, 56);
    cursor: pointer;
}

.menu__kebaba__wrapper {
    position: absolute;
    right: 35px;
    border-radius: 6px;
    overflow: hidden;
    top: 10px;
}
.menu__kebaba__wrapper button {
    background: rgb(238, 238, 240);
    opacity: 0.55;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 5px 10px;
}
.btn__menu__kebab:hover,
.menu__kebaba__wrapper button:hover {
    opacity: 0.9;
    cursor: pointer;
}
.menu__kebaba__wrapper button.active {
    opacity: 1;
}