.container {
    margin-top: 100px;
    padding: 32px;
}

.select-box {
    position: relative;
    display: flex;
    /* width: 400px; */
    flex-direction: column;
}

.select-box .options-container {
    /* background: #2f3640; */
    /* color: #f5f6fa; */
    max-height: 0;
    width: 100%;
    opacity: 0;
    transition: all 0.4s;
    border-radius: 8px;
    /* border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px; */
    overflow: hidden;
    order: 1;
}

.selected__option {
    /* background: #2f3640; */
    border-radius: 8px;
    margin-bottom: 8px;
    color: #f5f6fa;
    position: relative;
    font-size: 13px;
    order: 0;
}

.selected__option::after {
    content: "";
    /* background: url("img/arrow-down.svg"); */
    background: url("../../../../../../images/icones/svg/arrow-down.svg");
    background-size: contain;
    background-repeat: no-repeat;
    filter: invert(1);
    position: absolute;
    height: 100%;
    width: 22px;
    right: 10px;
    top: 10px;

    transition: all 0.4s;
}

.select-box .options-container.active {
    max-height: 240px;
    opacity: 1;
    overflow-y: auto;
    /* margin-top: 59px; */

    color: #495057;
    background-color: #fff;
    border: 1px solid #007bff70;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
    padding: 10px;
}
.select-box .options-container.active+.selected__option {
    color: #495057;
    background-color: #fff;
    border: 1px solid #007bff70;
    outline: 0;
    /* box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%); */
}
.select-box .options-container.active+.selected__option::after {
    transform: rotateX(180deg);
    top: -10px;
}

.select-box .options-container::-webkit-scrollbar {
    width: 8px;
    background: #0d141f;
    border-radius: 0 8px 8px 0;
}

.select-box .options-container::-webkit-scrollbar-thumb {
    background: #525861;
    border-radius: 0 8px 8px 0;
}
.select-box .option:nth-child(even) {
    background: #bfdeff18
}
.select-box .option:nth-child(odd) {
    background: #FFF
}
.select-box .option,
.selected__option {
    padding: 12px 10px;
    cursor: pointer;
}

.select-box .option:hover {
    background: #bfdeff30;
    border-radius: 8px;
}
.select-box .option:not(:hover).active {
    background: #bfdeffbf;
    border-radius: 8px;
}
.select-box .option.active {
    margin: 5px 0;
}
.select-box .option.active:first-child {
    margin: 0 0 5px !important;
}
.select-box label {
    cursor: pointer;
    vertical-align: middle !important;
}

.select-box .option .radio {
    display: none;
}

/* Searchbox */

.search-box input {
    width: 100%;
    padding: 12px 16px;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    /* position: absolute; */
    position: relative;
    border-radius: 8px 8px 0 0;
    z-index: 100;
    border: 8px solid #2f3640;

    opacity: 0;
    pointer-events: none;
    transition: all 0.4s;

    opacity: 1;
    pointer-events: auto;

    margin-bottom: 10px;
}
.search-box input:focus {
    outline: none;
    box-shadow: none !important;
}

.select-box .options-container.active~.search-box input {
    opacity: 1;
    pointer-events: auto;
}

@media (max-width: 300px) {
    .selected__option::after {
        top: 20px;
    }
    .select-box .options-container.active+.selected__option::after {
        top: -20px;
    }
}