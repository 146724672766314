.wrapper-account .content-myccount .img__user {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  padding: 3px;
  border: 2px solid #006DFF; 
}

.wrapper-account .content-myccount .body-account ul>li {
  list-style-type: none;
}

.wrapper-account .content-myccount .body-account ul li>a,
.wrapper-account .content-myccount .body-account ul li div>a {
  color: var(--light-grey);
}

.wrapper-account .content-myccount .body-account ul li>.active-account,
.wrapper-account .content-myccount .body-account ul li>.active-account,
.wrapper-account .content-myccount .body-account ul li .submenu__account.active-submenu .item__single__submenu__account a.active-submenu {
  border-left: 2px solid #006DFF;
  background-color: rgb(248, 248, 248);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  color: var(--text-color-4);
  font-weight: bold;
}

/* .wrapper-account .content-myccount .body-account ul li .submenu__account.active-submenu .item__single__submenu__account .active-submenu .item__single__submenu__account.sub__sub__menu a.active-submenu::before { */
.active-line-son {
  border-left: 2px solid #006DFF75 !important;
}

.active-line-son::before {
  content: " ";
  width: 30px;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0px;
  background-color: #006DFF75;
}

/* .wrapper-account .content-myccount .body-account ul li .submenu__account.active-submenu .item__single__submenu__account .active-submenu .item__single__submenu__account.sub__sub__menu a.active-submenu::after {
  content: " ";
  width: 100%;
  height: 2px;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ccc;
} */
.wrapper-account .content-myccount .body-account ul li buttom {
  text-align: right;
  padding-right: 10px;
  padding-left: 0;
  font-weight: bold;
}

.wrapper-account .content-myccount .body-account ul li buttom.active__options__admin {
  display: flex;
  transform: rotate(180deg);
  text-align: left;
  padding-left: 10px;
  padding-right: 0;
  font-weight: bold;
}

.wrapper-account .content-myccount .body-account ul li .active-account span>svg {
  color: var(--logo-color-dark);
}

.wrapper-account .content-myccount .body-account ul li .submenu__account.active-submenu .item__single__submenu__account a {
  border-left: 2px solid rgb(186, 182, 188);
}


.input-button {
  cursor: pointer;
  display: inline-block;
  padding-top: .5rem;
  position: relative;
  vertical-align: top;
}

.input-button input[type=checkbox],
.input-button input[type=radio] {
  left: .75rem;
  position: absolute;
  top: 50%;
  transform: translateY(-25%);
}

.input-button .label {
  background-color: #fff;
  border: .0625rem solid #a6a6a6;
  border-radius: .25rem;
  display: inline-block;
  padding: .68rem .81rem .68rem 2rem;
  transition: all .3s ease-in-out;
}

.box__contact__user .content__contact__user .box__single__contact {
  background-color: rgb(238, 238, 240);
}

.card__interesse__area__user {
  --background: #fff;
  --background-checkbox: #0082ff;
  --background-image: #fff, rgba(0, 107, 175, 0.2);
  --text-color: #666;
  --text-headline: #000;
  --card-shadow: #0082ff;
  /* --card-height: 190px;
    --card-width: 190px; */
  --card-radius: 12px;
  --header-height: 100%;
  --blend-mode: overlay;
  --transition: 0.15s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.card__interesse__area__user:nth-child(odd) .card__body-cover-image {
  --x-y1: 100% 90%;
  --x-y2: 67% 83%;
  --x-y3: 33% 90%;
  --x-y4: 0% 85%;
}

.card__interesse__area__user:nth-child(even) .card__body-cover-image {
  --x-y1: 100% 85%;
  --x-y2: 73% 93%;
  --x-y3: 25% 85%;
  --x-y4: 0% 90%;
}

.card__input {
  position: absolute;
  display: block;
  outline: none;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
}

.card__input:checked~.card__body {
  --shadow: 0 0 0 3px var(--card-shadow);
}

.card__input:checked~.card__body .card__body-cover-checkbox {
  --check-bg: var(--background-checkbox);
  --check-border: #fff;
  --check-scale: 1;
  --check-opacity: 1;
}

.card__input:checked~.card__body .card__body-cover-checkbox--svg {
  --stroke-color: #fff;
  --stroke-dashoffset: 0;
}

.card__input:checked~.card__body .card__body-cover:after {
  --opacity-bg: 0;
}

.card__input:checked~.card__body .card__body-cover-image {
  --filter-bg: grayscale(0);
}

.card__input:disabled~.card__body {
  cursor: not-allowed;
  opacity: 0.5;
}

.card__input:disabled~.card__body:active {
  --scale: 1;
}

.card__body {
  display: grid;
  grid-auto-rows: calc(var(--card-height) - var(--header-height)) auto;
  background: var(--background);
  height: var(--card-height);
  width: var(--card-width);
  border-radius: var(--card-radius);
  overflow: hidden;
  position: relative;
  cursor: pointer;
  box-shadow: var(--shadow, 0 4px 4px 0 rgba(0, 0, 0, 0.02));
  transition: transform var(--transition), box-shadow var(--transition);
  transform: scale(var(--scale, 1)) translateZ(0);
}

.card__body:active {
  --scale: 0.96;
}

.card__body-cover {
  --c-border: var(--card-radius) var(--card-radius) 0 0;
  --c-width: 100%;
  --c-height: 100%;
  position: relative;
  overflow: hidden;
}

.card__body-cover:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: var(--c-width);
  height: var(--c-height);
  border-radius: var(--c-border);
  background: linear-gradient(to bottom right, var(--background-image));
  mix-blend-mode: var(--blend-mode);
  opacity: var(--opacity-bg, 1);
  transition: opacity var(--transition) linear;
}

.card__body-cover-image {
  width: var(--c-width);
  height: var(--c-height);
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: var(--c-border);
  filter: var(--filter-bg, grayscale(1));
  -webkit-clip-path: polygon(0% 0%, 100% 0%, var(--x-y1, 100% 90%), var(--x-y2, 67% 83%), var(--x-y3, 33% 90%), var(--x-y4, 0% 85%));
  clip-path: polygon(0% 0%, 100% 0%, var(--x-y1, 100% 90%), var(--x-y2, 67% 83%), var(--x-y3, 33% 90%), var(--x-y4, 0% 85%));
}

.card__body-cover-checkbox {
  background: var(--check-bg, var(--background-checkbox));
  border: 2px solid var(--check-border, #fff);
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  opacity: var(--check-opacity, 0);
  transition: transform var(--transition), opacity calc(var(--transition) * 1.2) linear;
  transform: scale(var(--check-scale, 0));
}

.card__body-cover-checkbox--svg {
  width: 13px;
  height: 11px;
  display: inline-block;
  vertical-align: top;
  fill: none;
  margin: 7px 0 0 5px;
  stroke: var(--stroke-color, #fff);
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: var(--stroke-dashoffset, 16px);
  transition: stroke-dashoffset 0.4s ease var(--transition);
}

.card__body-header {
  min-height: var(--header-height);
  background: var(--background);
  padding: 0 10px 10px 10px;
}

.card__body-header-title {
  color: var(--text-headline);
  font-weight: 700;
  margin-bottom: 8px;
}

.card__body-header-subtitle {
  color: var(--text-color);
  font-weight: 500;
  font-size: 13px;
}

.box__interesse__area {
  display: grid;
  /* grid-template-columns: repeat(3, 1fr); */
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
}

/* PÁGINA DE PEDIDOS */
.wrapper__request__single {
  border-bottom: .0625rem solid #e5e5e5;
  /* height: 70px; */
  overflow: hidden;
}

.wrapper__request__single:last-child {
  border: none;
}

/* PEDIDOS - PEDIDO ABERTO */
.wrapper__request__single:not(.box__admin).is-open {
  /* height: auto;   */
  background-color: #f8f8f8;
  border-radius: 8px;
  /* margin: 20px 0;   */
}

.wrapper__request__single.box__admin.is-open {
  /* height: auto;   */
  background-color: #fff;
  border-radius: 8px;
  margin: 20px 0;
}

.wrapper__request__single .btn__show__request,
.wrapper__request__single .wrapper__order__request .delivery__order__action {
  padding: 10px;
  cursor: pointer;
}

.wrapper__request__single.is-open .btn__show__request,
.wrapper__request__single .wrapper__order__request.open .delivery__order__action {
  transform: rotate(180deg);
}

.wrapper__infos__request,
.wrapper__infos__product {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: "icon message close""button button button";
  background: rgb(236, 252, 253);
  border-radius: 8px;
  box-shadow: rgba(186, 182, 188, 0.07) 0px 0px 0px 1px, rgba(60, 55, 56, 0.07) 0px 0px 1px, rgba(161, 155, 162, 0.07) 0px 2px 2px;
  /* margin-top: 8px; */
  padding: 12px;
  width: 100%;
}

.wrapper__order__request {
  height: auto;
  overflow: hidden;
}

/* .wrapper__order__request.open{
  height: auto;
} */
.order-content {
  border-radius: 8px;
}

.wrapper__request__single:not(.box__admin) .order-content {
  background-color: #fff;
}

.wrapper__request__single.box__admin .order-content {
  background-color: #f8f8f8;
}

.wrapper__step__request {
  position: relative;
  min-height: 80px;
  width: 100%;
  margin: 0px auto;
  text-align: center;
}

.wrapper__step__request::after {
  background-color: rgb(211, 210, 214);
}

.wrapper__step__request::before,
.wrapper__step__request::after {
  top: calc(20px);
}

.wrapper__step__request::after {
  right: -1px;
}

.step__default::before,
.step__default::after {
  top: calc(20px);
}

.step__default::before {
  left: -1px;
}

.step__default:not(:first-child)::before,
.step__default:not(:last-child)::after {
  content: "";
  position: absolute;
  top: calc(17px);
  height: 4px;
  width: 50%;
  background-color: rgb(211, 210, 214);
  transform: translateY(-50%);
  z-index: 0;
}

.step__default {
  position: relative;
  min-height: 80px;
  width: 100%;
  margin: 0px auto;
  text-align: center;
}

.step__default__bullet {
  position: relative;
  display: inline-block;
  height: 35px;
  width: 35px;
  padding-top: calc(7px);
  background-color: rgb(211, 210, 214);
  border-radius: 100%;
  color: rgb(255, 255, 255);
  z-index: 1;
}

.wrapper__step__request::before,
.wrapper__step__request::after {
  content: "";
  position: absolute;
  top: calc(17px);
  height: 4px;
  width: 50%;
  background-color: rgb(65, 160, 23) !important;
  transform: translateY(-50%);
  z-index: 0;
}

.step__bullet__request {
  height: 40px;
  width: 40px;
  padding-top: 8px;
}

.step__bullet__request {
  position: relative;
  display: inline-block;
  height: 35px;
  width: 35px;
  padding-top: calc(7px);
  background-color: rgb(65, 160, 23);
  border-radius: 100%;
  color: rgb(255, 255, 255);
  z-index: 1;
}

.status__step__bullet {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0px;
}

.step__description__status {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  color: rgb(34, 32, 32);
}

.content__description__status {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 0px;
}

.itens__singel__request {
  border-bottom: .0625rem solid #e5e5e5;
}

/* ESTILO FIDELIDADE */
.content__bg__fidelidade {
  width: 100px;
  height: 100px;
  margin-left: 60px;
}

.wrapper__header__fidelidade {
  height: 160px;
  margin-top: -32px;
  background-position: center center;
}

.wrapper__header__fidelidade {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-top: -35px;
  /* background-image: url("https://cdn.leroymerlin.com.br/assets/images/lmcv/header/background-desktop.svg"); */
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.content__bg__fidelidade,
.content__avatar__fidelidade {
  position: relative;
  width: 100px;
  height: 100px;
  margin-left: 60px;
  margin-right: 95px;
  border: 1px solid #035397;
  border-radius: 50%;
  overflow: hidden;
}

.img__user__fidelidade {
  width: 100%;
  height: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  font-size: 24px;
  color: rgb(85, 79, 82);
  border-radius: 100%;
  background-color: rgb(161, 155, 162);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: rgba(3, 83, 151, 0.427) 0px 2px 6px;
}

.title__fidelidade {
  display: block;
  margin-bottom: 10px;
  color: #035397;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
}

svg:not(:root) {
  overflow: hidden;
}

.css-5yinnh {
  display: block;
  width: 118px;
}

.css-5yinnh path {
  fill: rgb(255, 255, 255);
}

.content__cupom__title__fidelidade {
  width: 245px;
  height: 75px;
  margin-top: 0px;
  margin-left: 40px;
  border: 1px solid #035397;
  border-radius: 24px;
}

.content__cupom__title__fidelidade {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
  white-space: pre-wrap;
}

.content__cupom__title__fidelidade::before,
.content__cupom__title__fidelidade::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 22px;
  height: 22px;
  background: #0194ff;
  transform: translateY(-50%);
}

.content__cupom__title__fidelidade::before {
  left: -11px;
  border: 1px solid #035397;
  border-radius: 50%;
}

.content__cupom__title__fidelidade::after {
  left: -23px;
}

.info__cupom__header__fidelidade {
  display: block;
  color: #03baff;
  font-size: 12px;
  line-height: 17px;
}

.info__cupom__header__fidelidade {
  display: none;
}

.info__cupom__body__fidelidade {
  font-size: 36px;
  line-height: 43px;
}

.info__cupom__body__fidelidade {
  display: inline-block;
  color: rgb(255, 255, 255);
  font-weight: 600;
}

.info__cupom__footer__fidelidade {
  display: inline-block;
  color: rgb(255, 255, 255);
  font-size: 18px;
}

/* CUPONS */
.cupom__single__fidelidade {
  position: relative;
  height: 300px;
  width: 172px;
  box-sizing: border-box;
}

.cupom__bg__fidel {
  display: block;
  position: absolute;
  top: -13px;
  left: -11px;
  z-index: 1;
}

.cupom__content__fidelidade {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 2;
  padding: 16px 16px 8px;
}

.icon__description__fidelidade {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  min-height: 190px;
}

.picture__fidelidade {
  position: relative;
  width: 84px;
  height: 84px;
  margin: 0px auto 8px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border: 3px solid rgb(238, 238, 240);
  border-radius: 100%;
  cursor: pointer;
}

.picture__image__fidelidade img,
.picture__image__fidelidade svg {
  display: block;
  width: 100%;
  height: 100%;
}

.picture__image__fidelidade img {
  border-radius: 100%;
}

.picture__icon__fidelidade {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 29px;
  height: 29px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  color: rgb(186, 182, 188);
  border-radius: 100%;
  border: 3px solid rgb(238, 238, 240);
  background: rgb(255, 255, 255);
  transition: background 300ms ease-in 0s;
}

.cupom__title__fidelidade {
  margin-top: 4px;
  margin-bottom: 8px;
}

.cupom__title__fidelidade p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 600;
  line-height: 20px;
}

.name__cupom__fidelidade {
  font-size: 16px;
}

.name__cupom__fidelidade {
  text-align: center;
  margin-bottom: 0px;
}

.divider__cupom__fidelidade {
  position: absolute;
  left: 18px;
  top: 206px;
  z-index: 900;
  width: calc(100% - 36px);
  border-bottom: 6px dotted rgb(238, 238, 240);
}

.wrapper__button__cupom__fidelidade {
  width: 100%;
  margin-top: 14px;
  -webkit-box-pack: start;
  justify-content: flex-start;
}

.stack__button__fidelidade {
  display: flex;
  flex-direction: column;
}

.wrapper__button__cupom__fidelidade>div:first-of-type {
  padding: 0px;
}

.stack__button__fidelidade>div:not(:last-child) {
  margin-bottom: 8px;
}

.button__stretch__fidelidade {
  position: relative;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: auto;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  outline: none;
  transition: color 200ms ease 0s, background 200ms ease 0s, border-color 200ms ease 0s, box-shadow 200ms ease 0s;
  color: rgb(255, 255, 255);
  background: #006eff;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  padding: calc(11px) 16px;
}

.text__button__fidelidade {
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0px;
}

.text__footer__cupom__fidelidade {
  color: rgb(161, 155, 162);
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
}

.text__footer__cupom__fidelidade p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 18px;
}

.content__footer__fidelidade {
  font-size: 13px;
}

.text__footer__cupom__fidelidade svg {
  display: inline;
  position: relative;
  margin-right: 4px;
  top: 1px;
}

.css-imgseb-icon-icon--color-undefined-icon--size-12px {
  color: currentcolor;
  width: 12px;
  height: 12px;
}

.wrapper__link__vantagens {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 300px;
  width: 120px;
  z-index: 900;
}

.container__link__vantagens {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  color: #0082ff;
}

.container__text__link__vantagens {
  margin-top: 12px;
  width: 84px;
}

.container__link__vantagens p {
  line-height: 19px;
  font-weight: 600;
}

.name__cupom__fidelidade {
  font-size: 16px;
  line-height: 24px;
}

.name__cupom__fidelidade {
  font-weight: 400;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0px;
}

.button__stretch__fidelidade:disabled,
.button__stretch__fidelidade[disabled] {
  pointer-events: none;
  cursor: not-allowed;
  box-shadow: none;
  color: rgb(186, 182, 188);
  background: rgb(238, 238, 240);
}

.button__stretch__fidelidade {
  position: relative;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: auto;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  outline: none;
  transition: color 200ms ease 0s, background 200ms ease 0s, border-color 200ms ease 0s, box-shadow 200ms ease 0s;
  color: rgb(255, 255, 255);
  background: #006eff;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  padding: calc(11px) 16px;
}

/* HISTORICO */
.header__historico__compras {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  box-shadow: rgba(3, 83, 151, 0.067) 0px 0px 0px 1px, rgba(3, 83, 151, 0.07) 0px 2px 2px, rgba(3, 83, 151, 0.07) 0px 4px 4px;
  border-radius: 16px;
}

.header__historico__compras {
  width: 837px;
  height: 142px;
  flex-direction: row;
  padding-top: 12px;
}

.wrapper__header__historico {
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
}

.wrapper__header__historico>div:not(:last-child) {
  margin-right: 32px;
}

.text__historico__points {
  font-weight: 800;
  color: rgb(34, 32, 32);
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 0px;
}

.wrapper__ticket__historico {
  width: 288px;
  height: 76px;
  border: 1px solid rgb(238, 238, 240);
  border-radius: 24px;
}

.wrapper__ticket__historico {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  white-space: pre-wrap;
}

.wrapper__ticket__historico::before {
  left: -11px;
  border: 1px solid rgb(238, 238, 240);
  border-radius: 100%;
}

.wrapper__ticket__historico::before,
.wrapper__ticket__historico::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 22px;
  height: 22px;
  background: rgb(255, 255, 255);
  transform: translateY(-50%);
}

.wrapper__ticket__historico::after {
  left: -23px;
}

.wrapper__ticket__historico::before,
.wrapper__ticket__historico::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 22px;
  height: 22px;
  background: rgb(255, 255, 255);
  transform: translateY(-50%);
}

.complement__text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0px;
}

.valid__points {
  font-weight: 400;
  color: rgb(111, 103, 108);
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 0px;
}

.ticket__value__historico {
  display: flex;
  -webkit-box-align: baseline;
  align-items: baseline;
  font-size: 27px;
  font-weight: 800;
  margin-right: 0px !important;
  background-image: linear-gradient(#006eff 100%, #035397 100%);
  color: black;
  background-clip: text;
  -webkit-background-clip: text;

  /* Adicionado: */
  -webkit-text-fill-color: transparent;
}

/* HISTORICO - LISTA DE COMPRAS */
.container__compras {
  position: relative;
  margin-top: 40px;
  margin-bottom: 0px;
  list-style: none;
}

.container__compras:first-of-type::before {
  content: "";
  position: absolute;
  top: -16px;
  left: 41px;
  display: block;
  width: 16px;
  height: 16px;
  border: 5px solid rgb(255, 255, 255);
  border-radius: 100%;
}

.container__compras::after {
  content: "";
  position: absolute;
  top: 25px;
  left: 75px;
  width: 2px;
  height: calc(100% - 60px);
  background: linear-gradient(#035397 7.81%, #006eff 12.5%, #006eff 54.69%, #03baff 61.98%, #035397 100%);
}

.wrapper__compra__single {
  margin: 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  height: 90px;
  padding: 0px 56px;
}

.content__compra {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
}

.type__payment__icon {
  position: relative;
  z-index: 1;
  width: 40px;
  height: 40px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  box-shadow: rgba(3, 83, 151, 0.07) 0px 0px 0px 1px, rgba(60, 55, 56, 0.07) 0px 4px 4px, rgba(60, 55, 56, 0.07) 0px 8px 8px;
  border-radius: 16px;
  background: #006eff;
  margin-right: 16px;
}

.type__payment__icon svg {
  width: 18px;
  height: 20px;
  margin-left: 1px;
}

.info__description__payment {
  display: block;
}

.date__payment {
  font-weight: 400;
  color: rgb(34, 32, 32);
  font-size: 13px;
  line-height: 15px;
  margin-bottom: 0px;
}

.value__payment {
  font-weight: 400;
  color: rgb(161, 155, 162);
  font-size: 13px;
  line-height: 15px;
  margin-bottom: 0px;
}

.wrapper__points__historico {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.content__points__single__historico {
  font-weight: 800;
  color: #006eff;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 0px;
}

.wrapper__points__historico span {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
}

/* DUVIDAS */

.css-uioitn-faq--item {
  margin: 0px 0px 12px;
  padding: 24px 16px;
  box-shadow: rgba(3, 83, 151, 0.067) 0px 0px 0px 1px, rgba(3, 83, 151, 0.07) 0px 4px 4px, rgba(3, 83, 151, 0.07) 0px 8px 8px;
  border-radius: 8px;
}

.css-wa24g9-title--container {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
}

.complement__text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0px;
}

.css-krnggn-text-text--bold-text--mega {
  margin-bottom: 16px;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
}

.css-cmlben-icon-icon--color-primary-icon--size-12px {
  display: block;
  color: #006eff;
  width: 12px;
  height: 12px;
}

.css-wa24g9-title--container svg {
  flex-shrink: 0;
  margin-left: 8px;
}

.css-1xe9zka-content__container {
  will-change: max-height;
  overflow: hidden;
  transition: max-height 455ms cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
}

.css-18q9vsc-content {
  margin-top: 12px;
  color: rgb(111, 103, 108);
}


/* RESPONSIVO */
@media (max-width: 820px) {
  .box__interesse__area {
    grid-template-columns: repeat(3, 1fr);
  }

}

@media (max-width: 645px) {
  .box__interesse__area {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 470px) {
  .box__interesse__area {
    grid-template-columns: repeat(1, 1fr);
  }

  /* RESPONSIVO STEP ACOMPANHAMENTO DE PEDIDO */
  .wrapper__step__progress {
    flex-direction: column;
  }

  .wrapper__step__request,
  .step__default {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    min-height: 50px;
    margin: 0px auto;
    text-align: center;
  }

  .wrapper__step__request:first-child::before,
  .wrapper__step__request:last-child::after {
    content: none;
  }

  .wrapper__step__request:not(:first-child)::before,
  .wrapper__step__request:not(:last-child)::after,
  .step__default:not(:first-child)::before,
  .step__default:not(:last-child)::after {
    content: "";
    position: absolute;
    height: 50%;
    width: 4px;
    left: 16px;
    /* background-color: rgb(65, 160, 23); */
    z-index: 0;
    top: auto !important;
  }

  .wrapper__step__request::after,
  .step__default::after {
    transform: translateY(50%) !important;
  }

  .step__default:last-child::after {
    content: "";
    position: absolute;
    height: 50%;
    width: 4px;
    left: 16px;
    z-index: 0;
    top: auto !important;
    top: 24px;
    background: linear-gradient(rgb(211, 210, 214), rgb(211, 210, 214) 50%, rgb(255, 255, 255) 50%, rgb(255, 255, 255)) 0% 0% / 16px 8px;
  }

  .wrapper__step__request::before,
  .step__default::before {
    transform: translateY(-50%) !important;
  }

  .step__description__status {
    margin: 0;
  }

  .content__description__status {
    margin-left: 8px;
  }

  .wrapper__step__request .content__description__status {
    color: rgb(161, 155, 162);
  }

  .content__description__status:last-child {
    display: none;
  }
}


/* PÁGINA DE CONTRATO INDIVIDUAL */
.content__contract {
  width: 100%;
  background: rgb(245, 245, 245);
  padding: 0 25px 25px;
  line-height: 1.75;
  border-radius: 8px;
  font-size: 14px;
}


/* TABELA DE ORÇAMENTOS */
.table__installment__budget {
  width: 100%;
  table-layout: auto;
  text-align: center;
  border-collapse: collapse;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: rgb(229, 230, 239) 0px 0px 0px 1px;
  word-break: break-word;
  /* hyphens: auto; */
}

.table__installment__budget thead th {
  font-size: 10pt;
  font-weight: bold;
  color: rgb(74, 74, 74);
  font-family: Roboto, "Noto Sans JP", Harmattan, "Noto Sans SC", "Noto Sans KR";
  display: table-cell;
  text-align: left;
  border: none;
  padding: 6px;
  background: rgb(242, 243, 254);

  border-radius: 4px;
  border-color: rgb(229, 230, 239);
  border-style: solid;
  position: relative;
  z-index: 1;
  border-width: 1px !important;
}

.table__installment__budget tbody td {
  font-size: 9pt;
  font-weight: normal;
  font-family: Roboto, "Noto Sans JP", Harmattan, "Noto Sans SC", "Noto Sans KR";
  text-align: left;
  font-style: normal;
  text-decoration: none;
  color: rgb(74, 74, 74);

  border-radius: 0 !important;

  overflow: visible;
  white-space: pre-line;

  border-radius: 4px;
  border-color: rgb(229, 230, 239);
  border-style: solid;
  position: relative;
  z-index: 1;
  border-width: 1px !important;

  padding: 6px;
  background: rgb(255, 255, 255);
}

.table__installment__budget tbody td:last-child {
  width: 20%;
}

.wrapper__budget {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100wh;
  box-sizing: border-box;
  margin-top: -20px;
}

.wrapper__budget>h4 {
  position: relative;
  z-index: 5;
}

.container__budget {
  position: relative;
  display: flex;
  justify-content: flex-end;
  height: 80px;
  width: 400px;
  border-radius: 15px;
  background: #f2f2ff;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.07),
    -10px -10px 20px rgba(255, 255, 255, 0.8);
  z-index: 1;
}


.container__budget::after {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  display: block;
  height: 50px;
  width: 170px;
  border-radius: 5px;
  background: #f2f2ff;
  box-shadow: inset 2px 2px 11px #e5e5e5,
    inset -5px -5px 11px #ffffff;
  z-index: 1;
  top: calc(50% - 25px);
  left: calc(50% - 170px);
  transition: all 0.4s cubic-bezier(1, 0.04, 0.66, 1.23);
}

.container__budget .cash-selected {
  animation: pressed-left 0.4s cubic-bezier(1, 0.04, 0.66, 1.23);
}

.container__budget .card-selected {
  animation: pressed-right 0.4s cubic-bezier(1, 0.04, 0.66, 1.23);
}

.container__budget .cash-selected::after {
  left: calc(50% - 170px);
}

.container__budget .card-selected::after {
  left: calc(50% - 0px);
}

.container__budget label {
  display: flex !important;
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 50%;
  cursor: pointer;
  height: 100%;
  z-index: 2;
}

.container__budget span {
  position: relative;
  z-index: 2;
}

.container__budget input {
  display: none;
}

.container__budget input:checked+label {
  color: #2180e1;
}

.container__budget input:checked+label::after {
  display: block;
}

@keyframes pressed-left {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(0.98, 0.98);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes pressed-right {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(0.98, 0.98);
  }

  100% {
    transform: scale(1, 1);
  }
}







/* ESTILO - STATUS DE RASTREAMENTO - INSTAGRAM */
.container__acompanhamento__status {
  display: flex;
}

.item__acompanhamento__status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 275px;
  transform: translate(0);
}

.item__acompanhamento__status .circle__acompanhamento__status {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88px;
  height: 88px;
  border: solid 5px #35f039;
  border-radius: 44px;
  transform: translate(0);
}

.item__acompanhamento__status .circle__acompanhamento__status::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  background: #020e26;
  transform: scaleX(1);
  transform-origin: 100% 0%;
  animation: 1s animate-circle forwards linear;
}

.item__acompanhamento__status:nth-child(2) .circle__acompanhamento__status::before {
  animation-delay: 2s;
}

.item__acompanhamento__status:nth-child(2) .circle__acompanhamento__status::before {
  animation-delay: 4s;
}

.item__acompanhamento__status:nth-child(2) .circle__acompanhamento__status::before {
  animation-delay: 6s;
}

@keyframes animate-circle {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

/* After (Line Animation) */
.item__acompanhamento__status .circle__acompanhamento__status::after {
  content: "";
  position: absolute;
  z-index: -1;
  background: #35f039;
  height: 5px;
}

.item__acompanhamento__status:not(:nth-child(4)) .circle__acompanhamento__status::after {
  left: 80px;
  animation: 1s animate-line forwards linear;
}

@keyframes animate-line {
  0% {
    width: 0;
  }

  100% {
    width: 100px;
  }
}

.item__acompanhamento__status:nth-child(1) .circle__acompanhamento__status::after {
  animation-delay: 1s;
}

.item__acompanhamento__status:nth-child(2) .circle__acompanhamento__status::after {
  animation-delay: 3s;
}

.item__acompanhamento__status:nth-child(3) .circle__acompanhamento__status::after {
  animation-delay: 5s;
}

/* ITEM ANIMATION */
.item__acompanhamento__status .circle__acompanhamento__status i {
  font-size: 32pt;
  color: #35f039;
  transform: scale(0);
  animation: 0.5s animate-icon forwards cubic-bezier(0.175, 0.885, 0.32, 1.4);
}

@keyframes animate-icon {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.item__acompanhamento__status:nth-child(1) .circle__acompanhamento__status i {
  animation-delay: 0.25s;
}

.item__acompanhamento__status:nth-child(2) .circle__acompanhamento__status i {
  animation-delay: 2.25s;
}

.item__acompanhamento__status:nth-child(3) .circle__acompanhamento__status i {
  animation-delay: 4.25s;
}

.item__acompanhamento__status:nth-child(4) .circle__acompanhamento__status i {
  animation-delay: 6.25s;
}

.item__acompanhamento__status .description__acompanhamento__status {
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  animation: 0.5s animate-descripton forwards ease-in;
}

@keyframes animate-description {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.item__acompanhamento__status:nth-child(1) .description__acompanhamento__status {
  animation-delay: 0.25s;
}

.item__acompanhamento__status:nth-child(2) .description__acompanhamento__status {
  animation-delay: 2.25s;
}

.item__acompanhamento__status:nth-child(3) .description__acompanhamento__status {
  animation-delay: 4.25s;
}

.item__acompanhamento__status:nth-child(4) .description__acompanhamento__status {
  animation-delay: 6.25s;
}

/* TEXT */
.item__acompanhamento__status h2 {
  color: #fff;
  font-size: 16pt;
  margin: 0;
  margin-top: 32px;
}

.item__acompanhamento__status h4 {
  color: #e2e2e2;
  font-size: 12pt;
  font-weight: 400;
  margin: 0;
  margin-top: 8px;
}

.text__status:after,
.text__status:before {
  content: '';
  display: block;
  width: 80px;
  height: 2px;
  background: var(--logo-color-dark);
  /* margin: 0 auto; */
}

.text__status__bot:after {
  content: '';
  display: block;
  width: 80px;
  height: 2px;
  background: var(--logo-color-dark);
  margin-top: 1.5px;
  /* margin: 0 auto; */
}

.text__status__top:before {
  content: '';
  display: block;
  width: 80px;
  height: 2px;
  background: var(--logo-color-dark);
  /* margin: 0 auto; */
}




/* ESTILO ACOMPANHAMENTO DE SERVIÇOS */
@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=DM+Sans:400,500,700&display=swap");

* {
  box-sizing: border-box;
}

:root {
  --app-container: #f3f6fd;
  --main-color: #1f1c2e;
  --secondary-color: #4A4A4A;
  --link-color: #1f1c2e;
  --link-color-hover: #c3cff4;
  --link-color-active: #fff;
  --link-color-active-bg: #1f1c2e;
  --projects-section: #fff;
  --message-box-hover: #fafcff;
  --message-box-border: #e9ebf0;
  --more-list-bg: #fff;
  --more-list-bg-hover: #f6fbff;
  --more-list-shadow: rgba(209, 209, 209, 0.4);
  --button-bg: #1f1c24;
  --search-area-bg: #fff;
  --star: #1ff1c2e;
  --message-btn: #fff;
}

.dark:root {
  --app-container: #1f1d2b;
  --app-container: #111827;
  --main-color: #fff;
  --secondary-color: rgba(255, 255, 255, .8);
  --projects-section: #1f2937;
  --link-color: rgba(255, 255, 255, .8);
  --link-color-hover: rgba(195, 207, 244, 0.1);
  --link-color-active-bg: rgba(195, 207, 244, 0.2);
  --button-bg: #1f2937;
  --search-area-bg: #1f2937;
  --message-box-hover: #243244;
  --message-box-border: rgba(255, 255, 255, .1);
  --star: #ffd92c;
  --light-font: rgba(255, 255, 255, .8);
  --more-list-bg: #2f3142;
  --more-list-bg-hover: rgba(195, 207, 244, 0.1);
  --more-list-shadow: rgba(195, 207, 244, 0.1);
  --message-btn: rgba(195, 207, 244, 0.1);
}

/* html, body {
  width: 100%;
  height: 100vh;
  margin: 0;
}

body {
  font-family: "DM Sans", sans-serif;
  overflow: hidden;
  display: flex;
  justify-content: center;
  background-color: var(--app-container);
} */

button,
a {
  cursor: pointer;
}

.app-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--app-container);
  transition: 0.2s;
  max-width: 1800px;
}

.app-container button,
.app-container input,
.app-container optgroup,
.app-container select,
.app-container textarea {
  font-family: "DM Sans", sans-serif;
}

.app-content {
  display: flex;
  height: 100%;
  overflow: hidden;
  padding: 16px 24px 24px 0;
}

.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 24px;
  position: relative;
}

.app-header-left,
.app-header-right {
  display: flex;
  align-items: center;
}

.app-header-left {
  flex-grow: 1;
}

.app-header-right button {
  margin-left: 10px;
}

.app-icon {
  width: 26px;
  height: 2px;
  border-radius: 4px;
  background-color: var(--main-color);
  position: relative;
}

.app-icon:before,
.app-icon:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 2px;
  border-radius: 4px;
  background-color: var(--main-color);
  left: 50%;
  transform: translatex(-50%);
}

.app-icon:before {
  top: -6px;
}

.app-icon:after {
  bottom: -6px;
}

.app-name {
  color: var(--main-color);
  margin: 0;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  margin: 0 32px;
}

.mode-switch {
  background-color: transparent;
  border: none;
  padding: 0;
  color: var(--main-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

/*
.search-wrapper {
  border-radius: 20px;
  background-color: var(--search-area-bg);
  padding-right: 12px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 480px;
  color: var(--light-font);
  box-shadow: 0 2px 6px 0 rgba(136, 148, 171, 0.2), 0 24px 20px -24px rgba(71, 82, 107, 0.1);
  overflow: hidden;
}
.dark .search-wrapper {
  box-shadow: none;
}

.search-input {
  border: none;
  flex: 1;
  outline: none;
  height: 100%;
  padding: 0 20px;
  font-size: 16px;
  background-color: var(--search-area-bg);
  color: var(--main-color);
}
.search-input:placeholder {
  color: var(--main-color);
  opacity: 0.6;
}
*/
.add-btn {
  color: #fff;
  background-color: var(--button-bg);
  padding: 0;
  border: 0;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-btn {
  color: var(--main-color);
  padding: 0;
  border: 0;
  background-color: transparent;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-btn {
  padding: 0;
  border: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  padding-left: 12px;
  border-left: 2px solid #ddd;
}

.profile-btn img {
  width: 32px;
  height: 32px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 4px;
}

.profile-btn span {
  color: var(--main-color);
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}

.page-content {
  flex: 1;
  width: 100%;
}

.app-sidebar {
  padding: 40px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app-sidebar-link {
  color: var(--main-color);
  color: var(--link-color);
  margin: 16px 0;
  transition: 0.2s;
  border-radius: 50%;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-sidebar-link:hover {
  background-color: var(--link-color-hover);
  color: var(--link-color-active);
}

.app-sidebar-link.active {
  background-color: var(--link-color-active-bg);
  color: var(--link-color-active);
}

.projects-section {
  flex: 2;
  background-color: var(--projects-section);
  border-radius: 8px;
  /* 32 */
  padding: 32px 32px 0 32px;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.projects-section-line {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 32px;
}

.projects-section-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  color: var(--main-color);
}

.projects-section-header p {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  opacity: 0.9;
  margin: 0;
  color: var(--main-color);
}

.projects-section-header .time {
  font-size: 20px;
}

.projects-status {
  display: flex;
  flex-wrap: wrap;
}

.item-status {
  display: flex;
  flex-direction: column;
  margin-right: 16px;
}

.item-status:not(:last-child) .status-type:after {
  content: "";
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translatey(-50%);
  width: 6px;
  height: 6px;
  border-radius: 50%;
  border: 1px solid var(--secondary-color);
}

.status-number {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: var(--main-color);
}

.status-type {
  position: relative;
  padding-right: 24px;
  color: var(--secondary-color);
}

.view-actions {
  display: flex;
  align-items: center;
}

.view-btn {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  border-radius: 4px;
  background-color: transparent;
  border: none;
  color: var(--main-color);
  margin-left: 8px;
  transition: 0.2s;
}

.view-btn.active {
  background-color: var(--link-color-active-bg);
  color: var(--link-color-active);
}

.view-btn:not(.active):hover {
  background-color: var(--link-color-hover);
  color: var(--link-color-active);
}

.messages-section {
  flex-shrink: 0;
  padding-bottom: 32px;
  background-color: var(--projects-section);
  margin-left: 24px;
  flex: 1;
  width: 100%;
  border-radius: 30px;
  position: relative;
  overflow: auto;
  transition: all 300ms cubic-bezier(0.19, 1, 0.56, 1);
}

.messages-section .messages-close {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 3;
  border: none;
  background-color: transparent;
  color: var(--main-color);
  display: none;
}

.messages-section.show {
  transform: translateX(0);
  opacity: 1;
  margin-left: 0;
}

.messages-section .projects-section-header {
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 32px 24px 0 24px;
  background-color: var(--projects-section);
}

.message-box {
  border-top: 1px solid var(--message-box-border);
  padding: 16px;
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.message-box:hover {
  background-color: var(--message-box-hover);
  border-top-color: var(--link-color-hover);
}

.message-box:hover+.message-box {
  border-top-color: var(--link-color-hover);
}

.message-box img {
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 40px;
  height: 40px;
}

.message-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.message-header .name {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: var(--main-color);
  margin: 0;
}

.message-content {
  padding-left: 16px;
  width: 100%;
}

.star-checkbox input {
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
}

.star-checkbox label {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.dark .star-checkbox {
  color: var(--secondary-color);
}

.dark .star-checkbox input:checked+label {
  color: var(--star);
}

.star-checkbox input:checked+label svg {
  fill: var(--star);
  transition: 0.2s;
}

.message-line {
  font-size: 14px;
  line-height: 20px;
  margin: 8px 0;
  color: var(--secondary-color);
  opacity: 0.7;
}

.message-line.time {
  text-align: right;
  margin-bottom: 0;
}

.project-boxes {
  margin: 0 -8px;
  overflow-y: auto;
}

.project-boxes.jsGridView {
  display: flex;
  flex-wrap: wrap;
}

.project-boxes.jsGridView .project-box-wrapper {
  width: 33.3%;
}

.project-boxes.jsListView .project-box {
  display: flex;
  border-radius: 10px;
  position: relative;
}

.project-boxes.jsListView .project-box>* {
  margin-right: 24px;
}

.project-boxes.jsListView .more-wrapper {
  position: absolute;
  right: 16px;
  top: 16px;
}

.project-boxes.jsListView .project-box-content-header {
  order: 1;
  max-width: 120px;
}

.project-boxes.jsListView .project-box-header {
  order: 2;
}

.project-boxes.jsListView .project-box-footer {
  order: 3;
  padding-top: 0;
  flex-direction: column;
  justify-content: flex-start;
}

.project-boxes.jsListView .project-box-footer:after {
  display: none;
}

.project-boxes.jsListView .participants {
  margin-bottom: 8px;
}

.project-boxes.jsListView .project-box-content-header p {
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.project-boxes.jsListView .project-box-header>span {
  position: absolute;
  bottom: 5px;
  /* 16px */
  left: 16px;
  font-size: 12px;
}

.project-boxes.jsListView .box-progress-wrapper {
  order: 3;
  flex: 1;
}

.project-box {
  --main-color-card: #dbf6fd;
  border-radius: 30px;
  padding: 16px;
  background-color: var(--main-color-card);
}

.project-box-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  color: var(--main-color);
}

.project-box-header span {
  color: #4A4A4A;
  opacity: 0.7;
  font-size: 14px;
  line-height: 16px;
}

.project-box-content-header {
  text-align: center;
  margin-bottom: 16px;
}

.project-box-content-header p {
  margin: 0;
}

.project-box-wrapper {
  padding: 8px;
  transition: 0.2s;
}

.project-btn-more {
  padding: 0;
  height: 14px;
  width: 24px;
  height: 24px;
  position: relative;
  background-color: transparent;
  border: none;
  flex-shrink: 0;
  /*&:after, &:before {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: var(--main-color);
    opacity: .8;
    left: 50%;
    transform: translatex(-50%);
  }

  &:before { top: 0;}
  &:after { bottom: 0; }*/
}

.more-wrapper {
  position: relative;
}

.box-content-header {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  opacity: 0.7;
}

.box-content-subheader {
  font-size: 14px;
  line-height: 24px;
  opacity: 0.7;
}

.box-progress {
  display: block;
  height: 4px;
  border-radius: 6px;
}

.box-progress-bar {
  width: 100%;
  height: 4px;
  border-radius: 6px;
  overflow: hidden;
  background-color: #fff;
  margin: 8px 0;
}

.box-progress-header {
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  margin: 0;
}

.box-progress-percentage {
  text-align: right;
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
}

.project-box-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 16px;
  position: relative;
}

.project-box-footer:after {
  content: "";
  position: absolute;
  background-color: rgba(255, 255, 255, 0.6);
  width: calc(100% + 32px);
  top: 0;
  left: -16px;
  height: 1px;
}

.participants {
  display: flex;
  align-items: center;
}

.participants img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  overflow: hidden;
  -o-object-fit: cover;
  object-fit: cover;
}

.participants img:not(:first-child) {
  margin-left: -8px;
}

.add-participant {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: none;
  background-color: rgba(255, 255, 255, 0.6);
  margin-left: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.days-left {
  background-color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
  border-radius: 20px;
  flex-shrink: 0;
  padding: 6px 16px;
  font-weight: 700;
}

.mode-switch.active .moon {
  fill: var(--main-color);
}

.messages-btn {
  border-radius: 4px 0 0 4px;
  position: absolute;
  right: 0;
  top: 58px;
  background-color: var(--message-btn);
  border: none;
  color: var(--main-color);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  display: none;
}

@media screen and (max-width: 980px) {
  .project-boxes.jsGridView .project-box-wrapper {
    width: 50%;
  }

  .status-number,
  .status-type {
    font-size: 14px;
  }

  .status-type:after {
    width: 4px;
    height: 4px;
  }

  .item-status {
    margin-right: 0;
  }
}

@media screen and (max-width: 880px) {
  .messages-section {
    transform: translateX(100%);
    position: absolute;
    opacity: 0;
    top: 0;
    z-index: 2;
    height: 100%;
    width: 100%;
  }

  .messages-section .messages-close {
    display: block;
  }

  .messages-btn {
    display: flex;
  }
}

@media screen and (max-width: 720px) {

  .app-name,
  .profile-btn span {
    display: none;
  }

  .add-btn,
  .notification-btn,
  .mode-switch {
    width: 20px;
    height: 20px;
  }

  .add-btn svg,
  .notification-btn svg,
  .mode-switch svg {
    width: 16px;
    height: 16px;
  }

  .app-header-right button {
    margin-left: 4px;
  }
}

@media screen and (max-width: 520px) {
  .projects-section {
    overflow: auto;
  }

  .project-boxes {
    overflow-y: visible;
  }

  .app-sidebar,
  .app-icon {
    display: none;
  }

  .app-content {
    padding: 16px 12px 24px 12px;
  }

  .status-number,
  .status-type {
    font-size: 10px;
  }

  .view-btn {
    width: 24px;
    height: 24px;
  }

  .app-header {
    padding: 16px 10px;
  }

  .search-input {
    max-width: 120px;
  }

  .project-boxes.jsGridView .project-box-wrapper {
    width: 100%;
  }

  .projects-section {
    padding: 24px 16px 0 16px;
  }

  .profile-btn img {
    width: 24px;
    height: 24px;
  }

  .app-header {
    padding: 10px;
  }

  .projects-section-header p,
  .projects-section-header .time {
    font-size: 18px;
  }

  .status-type {
    padding-right: 4px;
  }

  .status-type:after {
    display: none;
  }

  .search-input {
    font-size: 14px;
  }

  .messages-btn {
    top: 48px;
  }

  .box-content-header {
    font-size: 12px;
    line-height: 16px;
  }

  .box-content-subheader {
    font-size: 12px;
    line-height: 16px;
  }

  .project-boxes.jsListView .project-box-header>span {
    font-size: 10px;
  }

  .box-progress-header {
    font-size: 12px;
  }

  .box-progress-percentage {
    font-size: 10px;
  }

  .days-left {
    font-size: 8px;
    padding: 6px 6px;
    text-align: center;
  }

  .project-boxes.jsListView .project-box>* {
    margin-right: 10px;
  }

  .project-boxes.jsListView .more-wrapper {
    right: 2px;
    top: 10px;
  }
}

@media screen and (max-width: 320px) {

  #follow_up_obra_single .content-account,
  #follow_up_obra_single .wrapper__new__budget,
  #follow_up_obra_single .wrapper-account,
  .projects-section {
    padding-left: 5px;
    padding-right: 5px;
  }
}



/* ESTILO REACT PDF */
.react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
}

.view__anexos__wrapper:hover .page-controls,
.view__anexos__wrapper:hover .page-tools {
  opacity: 1;
}

.page-controls,
.page-tools {
  position: absolute;
  background: white;
  opacity: 0;
  transition: opacity ease-in-out .2s;
  box-shadow: 0 30px 40px 0 rgba(16, 36, 94, .2);
  border-radius: 4px;
}

.page-controls {
  bottom: 5%;
  left: 50%;
  transform: translate(-50%);
}

.page-tools {
  top: 2.5%;
  left: 5%;
  transform: translate(0);
  display: flex;
  flex-direction: column;
}

.page-tools button:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.page-tools button:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.page-controls button:enabled:hover,
.page-controls button:enabled:focus,
.page-tools button:enabled:hover {
  background-color: #e6e6e6;
}

.page-controls button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.page-controls button,
.page-tools button {
  width: 44px;
  height: 44px;
  background: white;
  border: 0;
  font: inherit;
  font-size: .8em;
}

.page-controls button {
  border-radius: 4px;
}

.page-controls span {
  font: inherit;
  font-size: .8em;
  padding: 0 .5em;
}

.page-controls button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

@media (max-width: 380px) {

  .page-controls button,
  .page-tools button {
    width: 24px;
    height: 34px;
  }
}

@media (max-width: 335px) {
  .page-controls button {
    width: 14px;
    height: 24px;
  }
}

@media (max-width: 300px) {
  .page-tools button svg {
    font-size: .8em;
  }

  .page-controls span {
    font-size: .5em;
  }
}


/* ESTILO CIRCULO CARREGANDO ETAPA CONCLUIDO */

.circle-loader {
  margin-bottom: 1em;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-left-color: #5cb85c;
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: 7em;
  height: 7em;
}

.load-complete {
  -webkit-animation: none;
  animation: none;
  border-color: #5cb85c;
  transition: border 500ms ease-out;
}

.checkmark {
  display: none;
}

.checkmark.draw:after {
  animation-duration: 800ms;
  animation-timing-function: ease;
  animation-name: checkmark;
  transform: scaleX(-1) rotate(135deg);
}

.checkmark:after {
  opacity: 1;
  height: 3.5em;
  width: 1.75em;
  transform-origin: left top;
  border-right: 3px solid #5cb85c;
  border-top: 3px solid #5cb85c;
  content: "";
  left: 1.75em;
  top: 3.5em;
  position: absolute;
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }

  20% {
    height: 0;
    width: 1.75em;
    opacity: 1;
  }

  40% {
    height: 3.5em;
    width: 1.75em;
    opacity: 1;
  }

  100% {
    height: 3.5em;
    width: 1.75em;
    opacity: 1;
  }
}


/* ESTILO GALERIA DE IMAGENS */

.galery {
  margin: 0;
}

.galery-item {
  margin: 0 0 0.5rem;
}

.galery-item .image {
  max-width: 100%;
  display: block;
}

.masonry {
  --columns: 2;
  /* --gap: .5rem; */
  --gap: 0 .5rem;
  display: grid;
  grid-template-columns: repeat(var(--columns), 1fr);
  grid-gap: var(--gap);
  /* gap: ; */
}

.masonry.masonry--columns_1 {
  --columns: 1;
}

.masonry.masonry--columns_2 {
  --columns: 2;
}

.masonry.masonry--columns_3 {
  --columns: 3;
}

.masonry.masonry--columns_4 {
  --columns: 4;
}

.masonry.masonry--columns_5 {
  --columns: 5;
}

.masonry.masonry--columns_6 {
  --columns: 6;
}

.masonry.masonry--columns_7 {
  --columns: 7;
}

.masonry.masonry--columns_8 {
  --columns: 8;
}

.masonry.masonry--columns_9 {
  --columns: 9;
}

.masonry.masonry--columns_10 {
  --columns: 10;
}

.masonry.masonry--columns_11 {
  --columns: 11;
}

.masonry.masonry--columns_12 {
  --columns: 12;
}

.masonry.masonry--columns_13 {
  --columns: 13;
}

.masonry.masonry--columns_14 {
  --columns: 14;
}

.masonry.masonry--columns_15 {
  --columns: 15;
}

.masonry.masonry--columns_16 {
  --columns: 16;
}

.masonry.masonry--columns_17 {
  --columns: 17;
}

.masonry.masonry--columns_18 {
  --columns: 18;
}

.masonry.masonry--columns_19 {
  --columns: 19;
}

.masonry.masonry--columns_20 {
  --columns: 20;
}

/* ESTILO FILEPOND */
.container__photo__pofile__client.modo__edicao__ativo .box__filepond__client {
  display: block;
}
.container__photo__pofile__client.modo__edicao__ativo .box__photo__client {
  display: none;
}
.box__photo__client {
  cursor: pointer;
}
.container__photo__pofile__client,
.box__photo__client .icon__remove__photo__client,
.box__photo__client > img {
  transition: .5s ease-in-out;
}
.box__photo__client:not(:hover) .icon__remove__photo__client {
  opacity: 0;
}
.box__photo__client:hover .icon__remove__photo__client {
  opacity: 1;
}
.box__photo__client:hover > img {
  opacity: .2;
}

/* ESTILO BIBLIOTECA DE UPLOAD */
.ant-upload.ant-upload-select,
.ant-upload-list-item-container{
  width: 100% !important;
  height: auto !important;
  aspect-ratio: 1/1 !important;
}


/* ESTILO MENU DE OPÇÕES */

:root {
  --color-bg-primary: #d0d6df;
  --color-bg-primary-offset: #f1f3f7;
  --color-bg-secondary: #fff;
  --color-text-primary: #3a3c42;
  --color-text-primary-offset: #898c94;
  --color-orange: #dc9960;
  --color-green: #1eb8b1;
  --color-purple: #657cc4;
  --color-black: var(--color-text-primary);
  --color-red: #d92027;
}

.menu {
  display: flex;
  flex-direction: column;
  background-color: var(--color-bg-secondary);
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(64, 64, 64, 0.15);
  position: absolute;
  right: 5px;
  top: 30px;
  z-index: 99;
  max-width: 200px;
  width: 100%;
}

.menu-list {
  margin: 0;
  display: block;
  width: 100%;
  padding: 8px;
}
.menu-list + .menu-list {
  border-top: 1px solid #ddd;
}

.menu-sub-list {
  display: none;
  padding: 8px;
  background-color: var(--color-bg-secondary);
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(64, 64, 64, 0.15);
  position: absolute;
  left: calc(100% + 10px);
  right: 0;
  z-index: 1;
  width: 100%;
  top: 0;
  flex-direction: column;
}
.menu-sub-list:hover {
  display: flex;
}

.menu-item {
  position: relative;
  list-style-type: none;
}

.menu-button {
  font: inherit;
  border: 0;
  padding: 8px 8px;
  padding-right: 36px;
  width: 100%;
  border-radius: 8px;
  text-align: left;
  display: flex;
  align-items: center;
  position: relative;
  background-color: var(--color-bg-secondary);
  color: var(--color-black);
}
.menu-button:hover {
  background-color: var(--color-bg-primary-offset);
}
.menu-button:hover + .menu-sub-list {
  display: flex;
}
.menu-button:hover svg {
  stroke: var(--color-text-primary);
}
.menu-button svg {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  stroke: var(--color-text-primary-offset);
}
.menu-button svg:nth-of-type(2) {
  margin-right: 0;
  position: absolute;
  right: 8px;
}
.menu-button--delete:hover {
  color: var(--color-red);
}
.menu-button--delete:hover svg:first-of-type {
  stroke: var(--color-red);
}
.menu-button--orange svg:first-of-type {
  stroke: var(--color-orange);
}
.menu-button--green svg:first-of-type {
  stroke: var(--color-green);
}
.menu-button--purple svg:first-of-type {
  stroke: var(--color-purple);
}
.menu-button--black svg:first-of-type {
  stroke: var(--color-black);
}
.menu-button--checked svg:nth-of-type(2) {
  stroke: var(--color-purple);
}