/*  estilo botão criar conta */
.infos-user-myaccount .btn__default__model:last-child:not(:hover) {
    background-color: transparent;
    color: #3089ff;
    transition: .25s ease;
}
.sidenav__menu .box__single ul li,
.sidenav__submenu .box__single ul li {
    list-style-type: none;
}
.sidenav__menu .box__single ul li a{
    color: #fff;
}
.sidenav__menu .box__single ul li a:hover,
.sidenav__menu .box__single ul li.submenu__active {
    /* background-color: rgba(255, 255, 255, 0.201); */
    background-color: #fff;
}
.sidenav__menu .box__single ul li a:hover,
.sidenav__menu .box__single ul li.submenu__active a {
    color: rgb(0, 38, 58);
}

.sidenav__submenu .box__single ul li a {
    color: rgb(0, 38, 58);
}
.sidenav__submenu .box__single ul li:hover {
    background-color: rgb(0, 38, 58);
    box-shadow: inset 0.4375rem 0 0.5625rem -0.4375rem rgb(255 255 255 / 70%);
}
.sidenav__submenu .box__single ul li:hover a {
    color: #fff;
}

/* ESTILO - SIDENAV - FILTRO RESPONSIVO - LEROY MERLIN */
.css-1ou1g4x-filters-mobile-filters-mobile--open {
    position: fixed;
    inset: 0px;
    height: 100%;
    width: 100%;
    background: rgb(238, 238, 240);
    display: flex;
    flex-flow: column nowrap;
    transition: transform 300ms ease 0s;
    z-index: 900;
    transform: translateX(0px);
}
.css-vvm28p-stack-stack--alignX-center {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
}
.css-vvm28p-stack-stack--alignX-center > div:not(:last-child) {
    margin-bottom: 24px;
}
.css-10lfwh0-filters-mobile__footer-filters-mobile__text span:first-of-type {
    color: var(--logo-color-dark);
}
.css-1kxt2mk-text-text--bold-text--mega-heading--no-margin {
    font-size: 16px;
    line-height: 24px;
}
.css-1kxt2mk-text-text--bold-text--mega-heading--no-margin {
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0px;
}
.css-1kxt2mk-text-text--bold-text--mega-heading--no-margin {
    font-size: 16px;
    line-height: 24px;
}
.css-1kxt2mk-text-text--bold-text--mega-heading--no-margin {
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0px;
}
.css-ip3p39-button-group-button-group--center-button-group--inline {
    position: relative;
    display: inline-flex;
    width: 100%;
    -webkit-box-pack: center;
    justify-content: center;
    flex-flow: row wrap;
}
.css-ip3p39-button-group-button-group--center-button-group--inline > button:not(:last-of-type), .css-ip3p39-button-group-button-group--center-button-group--inline > a:not(:last-of-type) {
    margin-right: 16px;
    margin-bottom: 0px;
}
.css-r31xhj-button:hover {
    color: var(--logo-color-dark);
    background: #006eff10;
    border-color: var(--logo-color-dark);
}
.css-r31xhj-button {
    position: relative;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: auto;
    height: auto;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
    user-select: none;
    border-style: solid;
    border-radius: 8px;
    outline: none;
    transition: color 200ms ease 0s, background 200ms ease 0s, border-color 200ms ease 0s, box-shadow 200ms ease 0s;
    color: var(--logo-color-dark);
    background: transparent;
    border-color: var(--logo-color-dark);
    border-width: 2px;
    font-size: 16px;
    line-height: 24px;
    padding: calc(11px) 16px;
}
.css-1mrxw8b-button {
    position: relative;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: auto;
    height: auto;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
    user-select: none;
    border-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-radius: 8px;
    outline: none;
    transition: color 200ms ease 0s, background 200ms ease 0s, border-color 200ms ease 0s, box-shadow 200ms ease 0s;
    color: rgb(255, 255, 255);
    background: var(--logo-color-dark);
    font-size: 16px;
    line-height: 24px;
    padding: calc(11px) 16px;
}