@media (max-width: 1280px) {
    /* ITENS DE OPÇÕES PARA ADICIONAR AO CARRINHO */
    .infos-list-single {
        flex-wrap: wrap;
    }
}
@media (max-width: 1150px) {
    /* CARD PROJETOS - PROJETOS PRONTOS */
    .box-cards-projetos .wrapper__projetos {
        justify-content: center;
    }
    .box-cards-projetos .box-card-projeto-single {
        min-width: 285px;
        width: 100%;
        margin: 2% !important;
    }
}
@media (max-width: 1050px) {
    /* NEWSLETTER */
    .content-input{
        justify-content: flex-end;
    }
    .content-input button{
        margin-top: 15px;
    }
}
@media (max-width: 990px) {
    /* SLIDES HOME */
    /* #slide_home{
        margin-top: 40px;
    } */
}
@media (max-width: 910px) {
    /* CARD PROJETOS - PROJETOS PRONTOS */
}
@media (max-width: 768px) {
    .infos-tec-projeto .box-content-infos .informacoes-projeto {
        flex-direction: column;
    }
    .infos-tec-projeto .box-content-infos .informacoes-projeto .info-single {
        max-width: 100%;
    }
    .product-payinfo .item {
        min-width: 100px;
    }
    
    /* CARDS - ATENDIMENTO (CONTATO/OUVIDORIA/VENDAS/PROMOCOES) */
    .bg-card {
        height: 100%;
    }
    .content-cards {
        flex-direction: column;
    }
    .card-single-help{
        width: auto;
        margin: 3%;
    }
}
@media (max-width: 600px) {
    /* NEWSLETTER */
    .content-input,
    .content-input input,
    .text-news{
        width: 100% !important;
    }
    .content-input input{
        margin-top: 15px;
    }

    
}
@media (max-width: 350px) {
    /* NEWSLETTER */
    .text-news h1{
        font-size: 14px;
    }
    /* FAIXA - COMPRA PELO WHATSAPP */
    .buy-whats a > span{
        font-size: 10px;
    }

}
@media (max-width: 320px) {
    /* CARD PROJETOS - PROJETOS PRONTOS */
    .box-cards-projetos .box-card-projeto-single {
        min-width: 0;
    }
    .box-cards-projetos .box-card-projeto-single .body-card .description-card h3{
        font-size: 1.15rem;
    }
    .box-cards-projetos .box-card-projeto-single .body-card .infos-card .infos-single .inline-item:hover .img-cor{
        margin-left: auto;
        margin-right: auto;
    }
}
@media (max-width: 250px) {
    /* NEWSLETTER */
    .text-news h1 strong{
        display: block;
    }

}
@media (max-width: 200px) {
    /* CARD PROJETOS - PROJETOS PRONTOS - RIBBON SHAPE*/
    .box-cards-projetos .box-card-projeto-single {
        margin-top: 15px !important;
    }
    .box-cards-projetos .box-card-projeto-single .header-card .card-preview span {    
        transform: scale(.35);
        top: -52px;
        left: -52px;
    }
    .box-cards-projetos .box-card-projeto-single .header-card .card-preview span strong{    
        font-size: 20px;
    }

}