#footer .company_widget{
  visibility: visible; animation-delay: 0.2s; animation-name: fadeInLeft;
}
#footer .about-widget{
  visibility: visible; animation-delay: 0.4s; animation-name: fadeInLeft;
}
#footer .about-widget{
  visibility: visible; animation-delay: 0.6s; animation-name: fadeInLeft;
}
#footer .social-widget{
  visibility: visible; animation-delay: 0.8s; animation-name: fadeInLeft;
}
/* ESTILO - FOOTER*/
/* BOTÃO IR PARA O TOPO - FOOTER */
/* #Progress
================================================== */

.progress-wrap {
position: fixed;
right: 50px;
bottom: 50px;
height: 46px;
width: 46px;
cursor: pointer;
display: block;
border-radius: 50px;
box-shadow: inset  0 0 0 2px rgba(255,255,255,0.2);
z-index: 1;
opacity: 0;
visibility: hidden;
transform: translateY(15px);
-webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.progress-wrap.active-progress {
opacity: 1;
visibility: visible;
transform: translateY(0);
}
.progress-wrap::after {
position: absolute;
/* font-family: 'unicons';
content: '\e84b'; */
content: url('../../../../../../images/icones/svg/arrow-top.svg');
text-align: center;
line-height: 46px;
font-size: 24px;
left: 0;
top: 0;
height: 46px;
width: 46px;
cursor: pointer;
display: block;
z-index: 1;
-webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.progress-wrap:hover::after {
opacity: 0.5;
}
.progress-wrap::before {
position: absolute;
/* font-family: 'unicons';
content: '\e84b'; */
/* content: url('../../../../../../images/icones/svg/arrow-top.svg'); */
text-align: center;
line-height: 46px;
font-size: 24px;
opacity: 0;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
left: 0;
top: 0;
height: 46px;
width: 46px;
cursor: pointer;
display: block;
z-index: 2;
-webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.progress-wrap:hover::before {
opacity: 1;
}
.progress-wrap svg path { 
fill: none; 
}
.progress-wrap svg.progress-circle path {
stroke: var(--grey);
stroke-width: 4;
box-sizing:border-box;
-webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
body.light .progress-wrap {
box-shadow: inset  0 0 0 2px rgba(0,0,0,0.2);
}
body.light .progress-wrap::after {
color: var(--black-blue);
}
body.light .progress-wrap svg.progress-circle path {
stroke: var(--black-blue);
}

.new_footer_area {
background: #fbfbfd;
position: relative;
bottom: 0;
}


.new_footer_top {
padding: 120px 0px 270px;
position: relative;
  overflow-x: hidden;
}
.new_footer_top .wrapper__single__footer{
  width: 25%;
}

.new_footer_area .footer_bottom {
padding-top: 5px;
padding-bottom: 0px;
}
.footer_bottom {
font-size: 14px;
font-weight: 300;
line-height: 20px;
color: #7f88a6;
padding: 27px 0px;
}
.new_footer_top .company_widget p {
font-size: 16px;
font-weight: 300;
line-height: 28px;
color: #6a7695;
margin-bottom: 20px;
}
.new_footer_top .company_widget .f_subscribe_two .btn_get {
border-width: 1px;
margin-top: 20px;
}
.btn_get_two:hover {
background: transparent !important;
color: var(--logo-color-light) !important;
}
.btn_get:hover {
color: var(--logo-color-light) !important;
background: var(--logo-color-light) !important;
border-color: var(--logo-color-light) !important;
-webkit-box-shadow: none;
box-shadow: none;
}
a:hover, a:focus, .btn:hover, .btn:focus, button:hover, button:focus {
text-decoration: none;
outline: none;
}



.new_footer_top .f_widget.about-widget .f_list li a:hover {
color: var(--logo-color-light);
}
.new_footer_top .f_widget.about-widget .f_list li {
margin: 0 0 11px 0;
}
.f_widget.about-widget .f_list li:last-child {
margin-bottom: 0px;
}
.f_widget.about-widget .f_list li {
margin-bottom: 15px;
}
.f_widget.about-widget .f_list {
margin-bottom: 0px;
display: grid;
}
.new_footer_top .f_social_icon a {
width: 44px;
height: 44px;
line-height: 43px;
background: transparent;
border: 1px solid #e2e2eb;
font-size: 24px;
}
.f_social_icon a {
width: 46px;
height: 46px;
border-radius: 50%;
font-size: 14px;
line-height: 45px;
color: #858da8;
/* display: inline-block; */
background: #ebeef5;
text-align: center;
-webkit-transition: all 0.2s linear;
-o-transition: all 0.2s linear;
transition: all 0.2s linear;
}
.ti-facebook:before {
content: "\e741";
}
.ti-twitter-alt:before {
content: "\e74b";
}
.ti-vimeo-alt:before {
content: "\e74a";
}
.ti-pinterest:before {
content: "\e731";
}

.btn_get_two {
-webkit-box-shadow: none;
box-shadow: none;
background: var(--logo-color-light) !important;
border-color: var(--logo-color-light) !important;
color: #fff;
}

.btn_get_two:hover {
background: transparent !important;
color: var(--logo-color-light) !important;
}

.new_footer_top .f_social_icon a:hover {
background: var(--logo-color-light);
border-color: var(--logo-color-light);
color:white;
}
.new_footer_top .f_social_icon a + a {
margin-left: 4px;
}
.new_footer_top .f-title {
margin-bottom: 30px;
color: #263b5e;
}
.f_600 {
font-weight: 600;
}
.f_size_18 {
font-size: 18px;
}
.new_footer_area h1,
.new_footer_area h2,
.new_footer_area h3,
.new_footer_area h4,
.new_footer_area h5,
.new_footer_area h6 {
color: #4b505e;
}
.new_footer_top .f_widget.about-widget .f_list li a {
color: #6a7695;
margin: 5px 0;
}


.new_footer_top .footer_bg {
position: absolute;
bottom: 0;
background: url("http://droitthemes.com/html/saasland/img/seo/footer_bg.png") no-repeat scroll center 0;
filter: hue-rotate(-35deg);
width: 100%;
height: 266px;
}

.new_footer_top .footer_bg .footer_bg_one {
background: url("https://1.bp.blogspot.com/-mvKUJFGEc-k/XclCOUSvCnI/AAAAAAAAUAE/jnBSf6Fe5_8tjjlKrunLBXwceSNvPcp3wCLcBGAsYHQ/s1600/volks.gif") no-repeat center center;
filter: hue-rotate(35deg);
width: 330px;
height: 105px;
background-size:100%;
position: absolute;
bottom: 0;
left: 30%;
-webkit-animation: myfirst 22s linear infinite;
animation: myfirst 22s linear infinite;
}

.new_footer_top .footer_bg .footer_bg_two {
background: url("https://1.bp.blogspot.com/-hjgfxUW1o1g/Xck--XOdlxI/AAAAAAAAT_4/JWYFJl83usgRFMvRfoKkSDGd--_Sv04UQCLcBGAsYHQ/s1600/cyclist.gif") no-repeat center center;
filter: hue-rotate(35deg);
width: 88px;
height: 100px;
background-size:100%;
bottom: 0;
left: 38%;
position: absolute;
-webkit-animation: myfirst 30s linear infinite;
animation: myfirst 30s linear infinite;

}
.form-control.memail{
width: 90%;
/* height: 30px; */
border: 1px solid #ccc;
background-color: var(--bg-color);
padding: 10px;
border-radius: 5px;
font-size: 15px;
color: #495057;
}
.form-control.memail:focus{
color: #495057;
background-color: #fff;
border-color: #80bdff;
outline: 0;
box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}
.text-right a{
font-weight: normal   ;
color: var(--logo-color-light);
}
/* BOOTSTRAP ROOT FOOTER */
.row {
display: flex;
flex-wrap: nowrap;
margin-right: auto;
margin-left: auto;
}
.col-lg-3 {
flex:0 0 auto;
width:25%;
}
.col-md-6 {
flex:0 0 25%;
width:25%;
}
.col-sm-5 {
flex:0 0 auto;
width:41.66666667%;
}
.col-sm-7 {
flex:0 0 auto;
width:58.33333333%;
}
.mb-0 {
margin-bottom:0!important;
}
.ml-5 {
margin-left: 5px;
}
.mg-0 {
margin:0!important;
}
.btn {
display:inline-block;
font-weight:400;
line-height:1.5;
/* color:#212529; */
text-align:center;
text-decoration:none;
vertical-align:middle;
cursor:pointer;
-webkit-user-select:none;
-moz-user-select:none;
user-select:none;
/* background-color:transparent; */
border:1px solid transparent;
font-size:1rem;
border-radius:.25rem;
transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
padding:.375rem .75rem;
}
.list-unstyled,.list-inline {
padding-left:0;
list-style:none;
}
.align-items-center {
align-items:center!important;
}
.form-floating>.form-control,.form-floating>.form-select {
height:calc(3.5rem+2px);
line-height:1.25;
}
.form-floating>.form-control {
  padding:1rem .75rem;
  }
  .input-group>.form-control,.input-group>.form-select {
    position:relative;
    flex:1 1 auto;
    width:1%;
    min-width:0;
    }
/* ANIMAÇÕES DOS BOTÕES DOS CARDS */
/* ANIMAÇÃO FOOTER */
@-moz-keyframes myfirst {
0% {
  left: -25%;
}
100% {
  left: 100%;
}
}

@-webkit-keyframes myfirst {
0% {
  left: -25%;
}
100% {
  left: 100%;
}
}

@keyframes myfirst {
0% {
  left: -25%;
}
100% {
  left: 100%;
}
}
@media (max-width: 1200px) {
  .new_footer_top .center {
    padding: 0 20px;
  }
}
@media (max-width: 768px) {
  .new_footer_top {
    padding-top: 60px;
  }
  .new_footer_top .center .row {
    flex-wrap: wrap;
  }
  .new_footer_top .center .wrapper__single__footer {
    width: 50%;
    margin: 10px auto;
  }
}
@media (max-width: 450px) {
  .new_footer_top {
    padding-top: 30px;
  }
  .new_footer_top .center .wrapper__single__footer {
    width: 100%;
  }
}