.clear{clear: both;}
header .new-header{
  width: 100%;
}
.new-header-wrapper{
  width: 100%;
  max-width: 1180px;
  margin: auto;
}
.header-logo{
  margin-left: 10px;
}
.new-header-wrapper .header-logo-wrapper{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  /* width: 100%; */
  height: 128px;
  /* margin-left: 5%; */
  /* display: none; */
}
.new-header-wrapper .header-box-content-wrapper .header-topbar span{
  position: absolute;
  color: #fff;
  font-size: 11px;
  margin: 6px;
  margin-left: 30%;
  z-index: 1;
}
.new-header-wrapper .header-box-content-wrapper .new-header-content-wrapper{
  width: 100%;
}
.new-header-wrapper .header-box-content-wrapper .new-header-content-wrapper .box-content-nav{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  float: right;
  clear: both;
  /* width: calc(100% - 11.875rem); */
}
.new-header-wrapper .header-box-content-wrapper .new-header-content-wrapper .box-content-nav .box-top-nav{
  position: relative;
  width: 100%;
  color: #fff;
  /* font-size: 11px; */
  background-color: #021722;
  padding: 8px 20px 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  align-items: center;
  background-color: #021722;
  display: flex;
  font-size: .6875rem;
  justify-content: space-between;
  position: relative;
  text-align: right;
}
.new-header-wrapper .header-box-content-wrapper .new-header-content-wrapper .box-content-nav .box-top-nav::before{
  left: -7.375rem;
  transform: skew(45deg);
}
.new-header-wrapper .header-box-content-wrapper .new-header-content-wrapper .box-content-nav .box-top-nav::after{
  /* right: -43.8%; */
  right: -900%;
}
.new-header-wrapper .header-box-content-wrapper .new-header-content-wrapper .box-content-nav .box-top-nav::before,
.new-header-wrapper .header-box-content-wrapper .new-header-content-wrapper .box-content-nav .box-top-nav::after{
  background-color: #021722;
  content: " ";
  height: 100%;
  /* left: -7.375rem; */
  position: absolute;
  top: 0;
  /* width: 100%; */
  width: 1000%;
  z-index: -1;
}
.new-header-wrapper .header-box-content-wrapper .new-header-content-wrapper .box-content-nav .box-top-nav .location-wrapper span{
  margin-right: 3px;
}
.new-header-wrapper .header-box-content-wrapper .new-header-content-wrapper .box-content-nav .box-top-nav .faq-wrapper span{
  margin-right: 3px;
}
.new-header-wrapper .header-box-content-wrapper .new-header-content-wrapper .box-content-nav .box-center-nav,
.new-header-wrapper .header-box-content-wrapper .new-header-content-wrapper .box-content-nav .box-center-nav .search-opts-user{
  height: 100px;
  /* background-color: #00263a; */

  position: relative;
  width: 100%;
  color: #fff;
  /* font-size: 11px; */
  padding: 8px 10px 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  align-items: center;
  display: flex;
  font-size: .6875rem;
  justify-content: space-between;
  position: relative;
  text-align: right;
}
.new-header-wrapper .header-box-content-wrapper .new-header-content-wrapper .box-content-nav .box-center-nav{
  align-items: flex-start;
  flex-direction: column;
  font-size: .8rem;
  z-index: 8;
}
.new-header-wrapper .header-box-content-wrapper .new-header-content-wrapper .box-content-nav .box-center-nav ul{
  display: flex;
}
.new-header-wrapper .header-box-content-wrapper .new-header-content-wrapper .box-content-nav .box-center-nav ul li.opt-single-menu{
  position: relative;
  list-style-type: none;
  padding: 0 1.125rem 0 .3125rem;
  /* z-index: -1; */
  z-index: 0;
}
.new-header-wrapper .header-box-content-wrapper .new-header-content-wrapper .box-content-nav .box-center-nav ul li.opt-single-menu:first-child{
  padding-left: 0 ;
}
.new-header-wrapper .header-box-content-wrapper .new-header-content-wrapper .box-content-nav .box-center-nav ul li.opt-single-menu a{
  color: #fff;
  text-decoration: none;
  font-size: 15px;
  padding: 10px 0 8px;
}
.new-header-wrapper .header-box-content-wrapper .new-header-content-wrapper .box-content-nav .box-center-nav ul li.opt-single-menu a.opt-nav-custom{
  background-color: #006DFF;
  color: #fff;
  padding-left: 8px;
  padding-right: 8px;
}
.new-header-wrapper .header-box-content-wrapper .new-header-content-wrapper .box-content-nav .box-center-nav ul li.opt-single-menu:hover a.opt-nav-custom{
  color: #00263a;
}
.new-header-wrapper .header-box-content-wrapper .new-header-content-wrapper .box-content-nav .box-center-nav ul li.opt-single-menu:hover a{
  color: #006DFF;
}
.new-header-wrapper .header-box-content-wrapper .new-header-content-wrapper .box-content-nav .box-center-nav ul li.opt-single-menu:hover .sub-menu-nav{
  display: block;
  -webkit-box-shadow: 0px 10px 10px 3px rgba(158,158,158,0.3);
  -moz-box-shadow: 0px 10px 10px 3px rgba(158,158,158,0.3);
  box-shadow: 0px 10px 10px 3px rgba(158,158,158,0.3);
}
.new-header-wrapper .header-box-content-wrapper .new-header-content-wrapper .box-content-nav .box-center-nav ul li.opt-single-menu .sub-menu-nav{
  display: none;
  /* flex-direction: row; */
  position: absolute;
  background: #00263a;
  text-align: left;
  flex-wrap: nowrap;
  width: 14.0625rem;
  top: 25px;
  left: -4px;
  z-index: 999;
}
.new-header-wrapper .header-box-content-wrapper .new-header-content-wrapper .box-content-nav .box-center-nav ul li.opt-single-menu .sub-menu-nav li{
  list-style-type: none;
  cursor: pointer;
}
.new-header-wrapper .header-box-content-wrapper .new-header-content-wrapper .box-content-nav .box-center-nav ul li.opt-single-menu .sub-menu-nav li a{
  /* padding: 8px; */
  /* margin-bottom: 5px; */
  padding: 8px;
  color: #fff;
}
.new-header-wrapper .header-box-content-wrapper .new-header-content-wrapper .box-content-nav .box-center-nav ul li.opt-single-menu .sub-menu-nav li a:last-child{
  margin-bottom: 0;
}
.new-header-wrapper .header-box-content-wrapper .new-header-content-wrapper .box-content-nav .box-center-nav ul li.opt-single-menu .sub-menu-nav li i{
  margin: 0 5px;
  font-size: 18px;
}
.new-header-wrapper .header-box-content-wrapper .new-header-content-wrapper .box-content-nav .box-center-nav ul li.opt-single-menu .sub-menu-nav li:hover{
  background-color: var(--text-color);
  color: #00263a;
}
.new-header-wrapper .header-box-content-wrapper .new-header-content-wrapper .box-content-nav .box-center-nav ul li.opt-single-menu .sub-menu-nav li:hover a{
  color: #00263a;
}
/* SUB DESCRIÇÃO - SUB MENU */
.new-header-wrapper .header-box-content-wrapper .new-header-content-wrapper .box-content-nav .box-center-nav ul li.opt-single-menu .sub-menu-nav li:hover .sub-desc-opt{
  display: block;
  -webkit-box-shadow: 0px 10px 10px 3px rgba(158,158,158,0.3);
  -moz-box-shadow: 0px 10px 10px 3px rgba(158,158,158,0.3);
  box-shadow: 0px 10px 10px 3px rgba(158,158,158,0.3);
}
.new-header-wrapper .header-box-content-wrapper .new-header-content-wrapper .box-content-nav .box-center-nav ul li.opt-single-menu .sub-menu-nav li .sub-desc-opt{
  display: none;
  position: absolute;
  left: 14.0625rem;
  top: 0;
  width: 16rem;
  background-color: #fff;
  /* padding: 8px;
  height: 100%; */
}
.new-header-wrapper .header-box-content-wrapper .new-header-content-wrapper .box-content-nav .box-center-nav ul li.opt-single-menu .sub-menu-nav li .sub-desc-opt li:hover a{
  background-color: #00263a;
  color: var(--text-color);
}
.new-header-wrapper .header-box-content-wrapper .new-header-content-wrapper .box-content-nav .box-center-nav ul li.opt-single-menu .sub-menu-nav li .sub-desc-opt li a{
  color: #00263a;
  padding: 8px;
}

.new-header-wrapper .header-box-content-wrapper .new-header-content-wrapper .box-content-nav .box-center-nav::before{
  /* left: -3.375rem;
  transform: skew(45deg); */
  left: -6.5rem;
}
.new-header-wrapper .header-box-content-wrapper .new-header-content-wrapper .box-content-nav .box-center-nav::after{
  /* right: -43.8%; */
  right: -900%;
}
.new-header-wrapper .header-box-content-wrapper .new-header-content-wrapper .box-content-nav .box-center-nav::before,
.new-header-wrapper .header-box-content-wrapper .new-header-content-wrapper .box-content-nav .box-center-nav::after{
  background-color: #00263a;
  content: " ";
  height: 100%;
  position: absolute;
  top: 0;
  /* width: 100%; */
  width: 1000%;
  z-index: -2;
}
.new-header-wrapper .header-box-content-wrapper .new-header-content-wrapper .box-content-nav .box-center-nav .box-sub-top-nav{
  /* width: 60%; */
  width: 100%;
}
.search-wrapper{
  width: 100%;
}
.search-wrapper form{
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-wrapper form .btn__sensitive__case__search{
  padding: 1px;
  border-radius: 3px;
  border: 1px solid #ccc;
}
.search-wrapper form .btn__sensitive__case__search.active{
  background-color: #1877f2;
  color: #fff;
  border-color: #006DFF;
}
.search-wrapper input{
  height: 45px;
  width: 100%;
  padding-left: 10px;
  font-size: 16px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  outline: 0;
  /* color: #ccc; */
  border: 1px solid rgb(238, 238, 240);
}
.search-wrapper input:focus,
.search-wrapper input:hover{
  border-left: 1px solid var(--logo-color-dark);
  border-top: 1px solid var(--logo-color-dark);
  border-bottom: 1px solid var(--logo-color-dark);
}
input::placeholder {
  color: rgb(220, 220, 220);
}
.search-wrapper button{
  height: 45px;
  padding: 0 15px;
  line-height: 53px;
  color: #fff;
  background-color: #006DFF;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  cursor: pointer;
}
.search-wrapper button:hover{
  background-color: #005fdb;
}

.new-header-wrapper .header-box-content-wrapper .new-header-content-wrapper .box-content-nav .box-center-nav .box-opts-sub-top-nav{
  /* width: 40%; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
}

.new-header-wrapper .header-box-content-wrapper .new-header-content-wrapper .box-content-nav .box-center-nav .box-opts-sub-top-nav .box-opt-single-wrapper{
  display: flex;
  /* align-items: flex-end; */
  align-items: center;
  cursor: pointer;
  margin: 0 5px;
}
.new-header-wrapper .header-box-content-wrapper .new-header-content-wrapper .box-content-nav .box-center-nav .box-opts-sub-top-nav .box-opt-single-wrapper .icon-primary .itens-cart{
    right: -2px;
    background: #006eff;
    font-size: 12px;
    height: 18px;
    width: 18px;
    border-radius: 50%;
}
.title-option-nav{
  /* width: 50%; */
  text-align: left;
  font-weight: lighter;
}
.faq-wrapper a{
  text-decoration: none;
}
.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
}

/* TOOLTIP DE LINGUAGENS */
.lenguages-overlay{
  background-color: rgba(0,0,0,.5);
  display: block;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
}
.box__tooltip__lenguages {
  background-color: #fff;
  border-radius: .625rem;
  box-shadow: 0 0 1.25rem 0 rgba(0,0,0,.5);
  font-family: Leroy Merlin Sans;
  max-width: 23rem;
  min-width: 200px;
  padding: .9375rem;
  position: absolute;
  right: -97px;
  top: 25px;
  z-index: 10;
}
.box__tooltip__lenguages:before {
background-color: #fff;
border-radius: .25rem;
content: "";
display: inline-block;
height: 1.25rem;
position: absolute;
right: .75rem;
top: -.5rem;
transform: rotate(45deg);
width: 1.25rem;
}

/* MODAL DE LOGIN */
.my-account-overlay {
  background-color: rgba(0,0,0,.5);
  display: block;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 19;
}
.my-account-box {
  background-color: #fff;
  border-radius: .625rem;
  box-shadow: 0 0 1.25rem 0 rgba(0,0,0,.5);
  display: block;
  font-family: Leroy Merlin Sans;
  max-width: 23rem;
  min-width: 17.5rem;
  padding: .9375rem;
  position: absolute;
  right: 0;
  top: 4rem;
  z-index: 20;
}
.my-account-box:before {
background-color: #fff;
border-radius: .25rem;
content: "";
display: inline-block;
height: 1.25rem;
position: absolute;
right: 2.8125rem;
top: -.5rem;
transform: rotate(45deg);
width: 1.25rem;
}
.my-account-box li:hover{
  transition: background 0.3s ease 0s;
  background: rgb(238, 238, 240);
}
.my-account-box ul li > button{
  background: none;
  cursor: pointer;
}

.my-account-box .user-login, 
.my-account-box .user-social {
display: flex;
flex-wrap: wrap;
justify-content: center;
}
.my-account-box .user-login-button-facebook, 
.my-account-box .user-social-button-facebook {
background-color: #1877f2 !important;
font-weight: 400 !important;
}
.my-account-box .user-login-button-facebook:before,
.my-account-box .user-social-button-facebook:before {
background-image: url(../../../../../../images/icones/svg/facebook.svg);
background-size: cover;
content: "";
height: 1.125rem;
margin-right: 1.125rem;
width: 1.125rem;
}

.my-account-box .user-login-button-google,
.my-account-box .user-social-button-google{
background-color: #fff !important;
border: .063rem solid #bab6bc !important;
color: #333 !important;
font-weight: 400 !important;
}
.my-account-box .user-login-button-google:before,
.my-account-box .user-social-button-google:before {
background-image: url(../../../../../../images/icones/svg/google.svg);
content: "";
height: 1rem;
margin-right: 1.125rem;
width: 1rem;
}
.my-account-box .user-login-button-apple,
.my-account-box .user-social-button-apple {
background-color: #000 !important;
color: #fff !important;
font-weight: 400 !important;
text-indent: 1.125rem !important;
}
.my-account-box .user-login-button-apple:before,
.my-account-box .user-social-button-apple:before {
background-image: url(../../../../../../images/icones/svg/apple-logo.svg);
background-repeat: no-repeat;
background-size: contain;
content: "";
height: 1.3rem;
margin-right: .5rem;
width: 1.3rem;
}
.my-account-box .user-login-button, 
.my-account-box .user-social-button {
align-items: center;
background-color: var(--logo-color-light);
border-radius: .5rem;
color: #fff;
cursor: pointer;
display: flex;
font-size: 16px;
font-weight: 600;
justify-content: center;
line-height: .625rem;
margin-bottom: 1rem;
min-height: 2.8125rem;
padding: .625rem 0;
transition: all .2s ease;
width: 100%;
}
.user-social-spacer {
color: #c8c8c8;
display: block;
font-size: .875rem;
margin-bottom: 1rem;
position: relative;
text-align: center;
width: 100%;
}
.user-social-spacer:after, .user-social-spacer:before {
background-color: #c8c8c8;
content: "";
display: inline-block;
height: .0625rem;
position: absolute;
top: 65%;
transform: translateY(-50%);
width: 6.25rem;
}
.user-social-spacer:before {
left: 0;
}
.user-social-spacer:after {
right: 0;
}
.my-account-box .user-login-link,
.my-account-box .user-social-link {
color: #333 !important;
cursor: pointer !important;
font-size: 16px !important;
margin-bottom: 1rem !important;
}
.my-account-box .user-login-link a,
.my-account-box .user-social-link a {
color: var(--logo-color-dark) !important;
display: inline !important;
}
.my-account-box .user-options {
border-top: .0625rem solid #e5e5e5;
display: flex;
font-size: 16px;
justify-content: space-around;
padding: 1rem 0 0;
position: relative;
}
.my-account-box .user-options a {
color: #333;
cursor: pointer;
}
.my-account-box .user-options .spacing {
align-items: center;
background-color: #ddd;
height: 1rem;
width: .0625rem;
}
.my-account-box .user-login-button:hover,
.my-account-box .user-social-button:hover {
box-shadow: 0 0 .625rem 0 rgba(0,0,0,.2);
}

.buy-whats{
  width: 100%;
  height: 40px;
  background-color: rgb(65, 160, 23);
  position: relative;
  /* top: 128px; */
  top: 0;
  z-index: 7;
}

.buy-whats a{
  color: #fff;
}

.buy-whats a > span{
  width: 100%;
  height: 40px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buy-whats a span > img{
  max-width: 20px;
  margin-right: 10px;
}

.box__dark__mode > button{
  overflow: visible !important;
  appearance: none;
  border: none;
  background-color: transparent;
  position: absolute;
  top: 50%;
  margin-top: 1px;
}
.box__dark__mode button > div{
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  margin-top: -19px;
  width: 132px;
  top: -50%;
  height: 40px;
}

.box__languages {
  top: -11px;
  background: #605e5e85;
  padding: 1px 10px;
  border-radius: 20px;
  z-index: 8;
}

/* ESTILO - BARRA DE BUSCA */
.result__search ul li{
  list-style-type: none;
}
/* .collun__search{
  margin: 10px 0;
} */
.collun__search:first-child{
  margin-right: 5px;
  margin-left: 10px;
  border-right: 1px solid #ededed;
}
.collun__search:last-child{
  margin-left: 5px;
  margin-right: 10px;
}

.result__search .produtos__busca li:last-child::after,
.result__search .produtos__busca li:last-child::before{
  content: none;
}


/* RESPONSIVO - LEROY - SUJO */
.new-header {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 3.375rem;
  position: relative;
}
.new-header-mobile {
  display: block;
  padding: .625rem 1.125rem;
  position: relative;
  width: 100%;
}
.new-header-mobile-overlay {
  background-color: rgba(0,0,0,.5);
  height: 100vh;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: all .3s ease;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  z-index: 120;
}
.new-header-mobile-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: .5rem;
}
.new-header-mobile-divisor.left {
  justify-content: flex-start;
}
.new-header-mobile-divisor.left, .new-header-mobile-divisor.right {
  align-items: center;
  display: flex;
}
.new-header-mobile-divisor {
  flex-basis: 50%;
}
.new-header-mobile .hamburguer-menu {
  display: flex;
  flex-flow: column nowrap;
  height: 1.5rem;
  margin-right: 2rem;
  width: 1.5rem;
}
.new-header-mobile .hamburguer-menu-bread {
  background-color: var(--logo-color-dark);
  display: block;
  height: .1875rem;
  margin-top: .1875rem;
  width: 100%;
}
.css-k3z3v5-wrapper-logo svg {
  margin: 0px;
  width: 60px;
  height: 60px;
  z-index: 900;
}
.css-9620ll-icon-icon--color-undefined-icon--size-16px {
  display: block;
  color: currentcolor;
  width: 16px;
  height: 16px;
}
.new-header-mobile-divisor.right {
  justify-content: flex-end;
}
.new-header-mobile-divisor.left, .new-header-mobile-divisor.right {
  align-items: center;
  display: flex;
}
.new-header-mobile-divisor {
  flex-basis: 50%;
}
.new-header-mobile-divisor .glyph {
  font-size: 1.5rem;
}
.glyph {
  font-family: Glyphs;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  speak: none;
}
.share-buttons-component {
  margin: 0 .375rem;
  position: relative;
}
.share-button-overlay {
  opacity: 0;
  position: fixed;
  transform: scale(-100%);
  transition: all .4s cubic-bezier(.075,.82,.165,1);
  z-index: 9999;
}
.share-buttons-component .icon-share-trigger {
  font-size: 2.375rem;
}
.icon-share-trigger {
  align-items: center;
  background: #fff;
  border-radius: 50%;
  color: #72bf44;
  font-size: 2.375rem;
  justify-content: center;
  transition: all .5s cubic-bezier(.19,1,.22,1);
}
.share-button-content {
  display: none;
}
.share-button-list {
  align-items: center;
  display: flex;
  flex-flow: column wrap;
  font-size: 0;
  margin: 0;
  position: relative;
}
.share-button-item {
  display: none;
  margin-top: .75rem;
}
.share-button-list .share-button.share-button-twitter {
  background: #08a0e9;
  color: #fff;
}
.share-button-list .share-button {
  align-items: center;
  border-radius: 50%;
  display: flex;
  font-size: 1.125rem;
  height: 2.375rem;
  justify-content: center;
  width: 2.375rem;
}
.css-17xkciz {
  position: relative;
  color: rgb(120, 190, 32);
  font-size: 26px;
  margin: 0px 2px;
}
.drawer-overlay {
  background-color: rgba(0,0,0,.8);
  display: none;
  flex-flow: row nowrap;
  height: 100%;
  justify-content: flex-end;
  opacity: 0;
  overflow: hidden;
  transition: opacity .3s ease-in-out;
  width: 100vw;
  z-index: 9999;
}
.drawer {
  background: #fff;
  max-height: 100%;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;
  transform: translate(100%);
  transition: transform .4s cubic-bezier(.23,1,.32,1);
  width: 100vw;
  z-index: 10000;
}
.stock-info-drawer {
  display: flex;
  flex-direction: row;
  height: 100vh;
  padding: 0;
  width: 100vw;
}
.css-d472k5-container {
  width: 100%;
}
.new-header-mobile-bottom {
  height: 4.75rem;
}
.css-1dzcjy5-search__wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 44px;
}
.css-1h7vqth-text-field-search {
    margin-right: -50px;
    width: 100%;
}
/* .css-1h7vqth-text-field-search > div {
  width: 100%;
} */
.css-1h7vqth-text-field-search label {
  margin-bottom: 0px;
}
.css-fat8q2-label {
  display: block;
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 800;
  color: rgb(34, 32, 32);
}
.css-lxk6qv-input__container {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.css-q8aty4-input {
  width: 100%;
  box-sizing: border-box;
  color: rgb(34, 32, 32);
  text-align: left;
  border: 1px solid rgb(238, 238, 240);
  border-radius: 8px;
  outline: 0px;
  appearance: none;
  transition: color 200ms ease 0s, border-color 200ms ease 0s, box-shadow 200ms ease 0s;
  font-size: 16px;
  line-height: 24px;
  padding: 12px;
}
.css-1h7vqth-text-field-search input {
  height: 44px;
  padding-right: 50px;
}
.css-1qvo8qb-search__button {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 50px;
  height: 100%;
  z-index: 1;
  border-radius: 0px 8px 8px 0px;
  background-color: var(--logo-color-dark);
  cursor: pointer;
}
.css-dvq4u4-icon-icon--color-undefined-icon--size-20px {
  display: block;
  color: currentcolor;
  width: 20px;
  height: 20px;
}
svg:not(:root) {
  overflow: hidden;
}
.css-1qvo8qb-search__button > svg {
  color: rgb(255, 255, 255);
}
.location-info {
  cursor: pointer;
  display: block;
  font-size: .875rem;
  padding-top: .625rem;
  text-align: center;
}
.css-1bf1vc-wrapper-location-info {
  display: flex;
  align-items: flex-start;
  -webkit-box-pack: center;
  justify-content: center;
}
.css-1bf1vc-wrapper-location-info svg {
  align-self: center;
}
.css-qzh628-wrapper-mobile-menu {
  position: relative;
}
.new-header-mobile-layer.is-active, .new-header-mobile-layer.is-opened {
  overflow-x: hidden;
  transform: translateX(0);
}
.css-qzh628-wrapper-mobile-menu > div {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  overflow-y: auto;
}
.new-header-mobile-layer {
  background-color: #00263a;
  color: #fff;
  height: 100vh;
  left: 0;
  overflow-y: hidden;
  position: fixed;
  top: 0;
  transform: translateX(-100%);
  transition: all .3s ease;
  width: 85%;
  z-index: 1001;
}
.css-eh55wd-wrapper-logout {
  border-bottom: 2px solid rgb(24, 65, 93);
  padding: 16px;
}
.css-1t5pnb1-inline-inline-alignY--center {
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
}
.css-1t5pnb1-inline-inline-alignY--center > div:not(:last-child) {
  margin-right: 16px;
}
.css-b0j9sw-user-avatar-user-avatar--logged-out {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-size: 22px;
  color: rgb(136, 127, 135);
  background: rgb(238, 238, 240);
  border-radius: 100%;
  border: 5px solid rgb(16, 39, 57);
  width: 65px;
  height: 65px;
}
.css-b0j9sw-user-avatar-user-avatar--logged-out svg {
  color: rgb(136, 127, 135);
  width: 20px;
  height: 20px;
}
.css-kn5czj-stack {
  display: flex;
  flex-direction: column;
}
.css-egebze-inline {
  display: flex;
  flex-direction: row;
}
.css-1fmlwk9-button {
  position: relative;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: auto;
  height: auto;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  outline: none;
  transition: color 200ms ease 0s, background 200ms ease 0s, border-color 200ms ease 0s, box-shadow 200ms ease 0s;
  color: rgb(255, 255, 255);
  background: rgb(65, 160, 23);
  font-size: 13px;
  line-height: 20px;
  padding: calc(7px) 16px;
}
.css-nni1kt-button {
  position: relative;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: auto;
  height: auto;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  border-style: solid;
  border-radius: 8px;
  outline: none;
  transition: color 200ms ease 0s, background 200ms ease 0s, border-color 200ms ease 0s, box-shadow 200ms ease 0s;
  color: rgb(65, 160, 23);
  background: transparent;
  border-color: rgb(65, 160, 23);
  border-width: 2px;
  font-size: 13px;
  line-height: 20px;
  padding: calc(7px) 16px;
}
.css-1ijqpmo-wrapper-list-styles ul {
  padding: 12px 24px 0px;
}
.css-yp60lh-wrapper-menu-list {
  font-size: 16px;
  margin-bottom: 24px;
}
.css-1hutkq6-text-text-color--white-text--mega-heading--no-margin {
  font-weight: 400;
  color: rgb(255, 255, 255);
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0px;
}
.new-header-mobile-layer .menu-list-mobile-categories {
  background-color: #00263a;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  transform: translateX(-100%);
  transition: all .3s ease;
  width: 100%;
}
.new-header-mobile-layer .menu-list-mobile-categories-title {
  align-items: center;
  border-bottom: .0625rem solid #0c4664;
  display: flex;
  height: 4rem;
  justify-content: flex-start;
  padding: .6875rem 0;
}
.new-header-mobile-layer .menu-list-mobile-categories .menu-element-icon {
  align-items: center;
  display: flex;
}
.new-header-mobile-layer .menu-list-mobile-categories .menu-element-icon svg {
  fill: #fff;
  margin: 0 1rem;
}
.new-header-mobile-layer .menu-list-mobile-categories .menu-list {
  margin: 0;
  max-height: 92vh;
  overflow-y: auto;
}