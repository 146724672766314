/* ESTILO MODAL LEROY MERLIN - BOTÃO LOCALIZACAO */
.css-1fw07hd-button-button--stretch {
    position: relative;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: auto;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
    user-select: none;
    border-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-radius: 8px;
    outline: none;
    transition: color 200ms ease 0s, background 200ms ease 0s, border-color 200ms ease 0s, box-shadow 200ms ease 0s;
    color: rgb(0 110 255);
    background: transparent;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    padding: calc(11px) 16px;
}

.css-ev9b97-button__icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

.css-1fw07hd-button-button--stretch:hover {
    color: rgb(0 110 255);
    background: rgba(0, 110, 255, 0.1);
}

/*  ESTILO BOTÃO MODAL */
.btn__cancel__modal {
    padding: 5px 15px;
    border-radius: 6px;
    margin: 0 10px;
    background: none;
    color: #404040;
}

.btn__cancel__modal:hover {
    background: #006eff10;
}

.btn__confirm__modal {
    padding: 5px 15px;
    border-radius: 6px;
    margin: 0 10px;
    background: #006eff;
    color: #fff;
}

.btn__confirm__modal:hover {
    background: #035397;
}


/* MODAL - BLOCK COPYRIGHT */
/* .popup .icon__header {
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 50px;
    padding: 25px 25px 10px;
    background: #f44336;
    margin: 0;
    border-radius: 100px;
} */
.popup .icon__header {
    width: 100px;
    height: 100px;
    overflow: hidden;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 50px;
    padding: 25px 25px 10px;
    margin: 0;
}

.octagon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    transform: rotate(45deg);
    background: #f35831;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 3px solid red; */
}

.octagon:before {
    position: absolute;
    /* There needs to be a negative value here to cancel
     * out the width of the border. It's currently -3px,
     * but if the border were 5px, then it'd be -5px.
     */
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    transform: rotate(45deg);
    content: '';
    border: inherit;
}



/* ESTILO PARA ASSINATURA */

#signature__drawer .signature__canvas {
    color: #495057;
    background: #ffffff;
    border: 1px solid #ced4da;
    outline: 0 none;
    font-size: 1rem;
    border-radius: 4px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    -webkit-appearance: none;
    appearance: none;
    max-width: 350px;
    max-height: 100px;
    min-height: 100px;
    width: 100%;
}

#signature__drawer .signature__canvas:active {
    color: #495057;
    background-color: #fff;
    border-color: #007bff70;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

#signature__drawer .signature__canvas.invalid{ 
    color: #495057;
    background-color: #fff;
    border-color: #dc354570;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
}

#signature__drawer.saved .w65::after {
    content: ' ';
    position: absolute;
    top: 0;
    color: #495057;
    background: #aeaeae40;
    outline: 0 none;
    font-size: 1rem;
    border-radius: 4px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    -webkit-appearance: none;
    appearance: none;
    width: 102%;
    max-height: 100px;
    min-height: 100px;
}

#signature__drawer.saved .signature__canvas {
    border: none;
}

/*
*/
#signature {
    height: 100%;
    width: 100%;

}

#signature::after {
    content: '';
    display: block;
    width: 80px;
    height: 3px;
    background: #6d2db0;
    margin: 0 auto;
}


/* ESTILO MODAL RECORTAR IMAGEM */
/* .disp-none {
    display: none;
}

.container-form {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
    padding-bottom: 0;
}

.container-image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

#confirm-img {
    height: 218px;
    border-radius: 4px;
}
*/
/* body {
    background: linear-gradient(90deg, rgba(19, 20, 23, 1) 0%, rgba(30, 31, 38, 1) 100%, rgba(30, 31, 38, 1) 100%);
} */
/*
#upload-aphoto {
    background: red;
    border-color: red;
} */