/* BOTÃO ASSINAR */
.continue-application {
  --color: #fff;
  --background: #404660;
  --background-hover: #3A4059;
  --background-left: #2B3044;
  --folder: #F3E9CB;
  --folder-inner: #BEB393;
  --paper: #FFFFFF;
  --paper-lines: #BBC1E1;
  --paper-behind: #E1E6F9;
  --pencil-cap: #fff;
  --pencil-top: #275EFE;
  --pencil-middle: #fff;
  --pencil-bottom: #5C86FF;
  --shadow: rgba(13, 15, 25, .2);
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  padding: 17px 29px 17px 69px;
  transition: background 0.3s;
  color: var(--color);
  background: var(--bg, var(--background));
}

.continue-application>div {
  top: 0;
  left: 0;
  bottom: 0;
  width: 53px;
  position: absolute;
  overflow: hidden;
  border-radius: 5px 0 0 5px;
  background: var(--background-left);
}

.continue-application>div .folder {
  width: 23px;
  height: 27px;
  position: absolute;
  left: 15px;
  top: 13px;
}

.continue-application>div .folder .top {
  left: 0;
  top: 0;
  z-index: 2;
  position: absolute;
  transform: translateX(var(--fx, 0));
  transition: transform 0.4s ease var(--fd, 0.3s);
}

.continue-application>div .folder .top svg {
  width: 24px;
  height: 27px;
  display: block;
  fill: var(--folder);
  transform-origin: 0 50%;
  transition: transform 0.3s ease var(--fds, 0.45s);
  transform: perspective(120px) rotateY(var(--fr, 0deg));
}

.continue-application>div .folder:before,
.continue-application>div .folder:after,
.continue-application>div .folder .paper {
  content: "";
  position: absolute;
  left: var(--l, 0);
  top: var(--t, 0);
  width: var(--w, 100%);
  height: var(--h, 100%);
  border-radius: 1px;
  background: var(--b, var(--folder-inner));
}

.continue-application>div .folder:before {
  box-shadow: 0 1.5px 3px var(--shadow), 0 2.5px 5px var(--shadow), 0 3.5px 7px var(--shadow);
  transform: translateX(var(--fx, 0));
  transition: transform 0.4s ease var(--fd, 0.3s);
}

.continue-application>div .folder:after,
.continue-application>div .folder .paper {
  --l: 1px;
  --t: 1px;
  --w: 21px;
  --h: 25px;
  --b: var(--paper-behind);
}

.continue-application>div .folder:after {
  transform: translate(var(--pbx, 0), var(--pby, 0));
  transition: transform 0.4s ease var(--pbd, 0s);
}

.continue-application>div .folder .paper {
  z-index: 1;
  --b: var(--paper);
}

.continue-application>div .folder .paper:before,
.continue-application>div .folder .paper:after {
  content: "";
  width: var(--wp, 14px);
  height: 2px;
  border-radius: 1px;
  transform: scaleY(0.5);
  left: 3px;
  top: var(--tp, 3px);
  position: absolute;
  background: var(--paper-lines);
  box-shadow: 0 12px 0 0 var(--paper-lines), 0 24px 0 0 var(--paper-lines);
}

.continue-application>div .folder .paper:after {
  --tp: 6px;
  --wp: 10px;
}

.continue-application>div .pencil {
  height: 2px;
  width: 3px;
  border-radius: 1px 1px 0 0;
  top: 8px;
  left: 105%;
  position: absolute;
  z-index: 3;
  transform-origin: 50% 19px;
  background: var(--pencil-cap);
  transform: translateX(var(--pex, 0)) rotate(35deg);
  transition: transform 0.4s ease var(--pbd, 0s);
}

.continue-application>div .pencil:before,
.continue-application>div .pencil:after {
  content: "";
  position: absolute;
  display: block;
  background: var(--b, linear-gradient(var(--pencil-top) 55%, var(--pencil-middle) 55.1%, var(--pencil-middle) 60%, var(--pencil-bottom) 60.1%));
  width: var(--w, 5px);
  height: var(--h, 20px);
  border-radius: var(--br, 2px 2px 0 0);
  top: var(--t, 2px);
  left: var(--l, -1px);
}

.continue-application>div .pencil:before {
  -webkit-clip-path: polygon(0 5%, 5px 5%, 5px 17px, 50% 20px, 0 17px);
  clip-path: polygon(0 5%, 5px 5%, 5px 17px, 50% 20px, 0 17px);
}

.continue-application>div .pencil:after {
  --b: none;
  --w: 3px;
  --h: 6px;
  --br: 0 2px 1px 0;
  --t: 3px;
  --l: 3px;
  border-top: 1px solid var(--pencil-top);
  border-right: 1px solid var(--pencil-top);
}

.continue-application:before,
.continue-application:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 2px;
  border-radius: 1px;
  background: var(--color);
  transform-origin: 9px 1px;
  transform: translateX(var(--cx, 0)) scale(0.5) rotate(var(--r, -45deg));
  top: 26px;
  right: 16px;
  transition: transform 0.3s;
}

.continue-application:after {
  --r: 45deg;
}

.continue-application:not(:disabled):hover {
  --cx: 2px;
  --bg: var(--background-hover);
  --fx: -40px;
  --fr: -60deg;
  --fd: .15s;
  --fds: 0s;
  --pbx: 3px;
  --pby: -3px;
  --pbd: .15s;
  --pex: -24px;
}

/* BOTÃO VISUALIZAR */
/* https://uiverse.io/barisdogansutcu/black-duck-67 */
.view-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-family: inherit;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.4px;
  border-radius: 6px;
  padding: 7px 20px 7px 20px;
  transform: translate(0px, 0px) rotate(0deg);
  transition: 0.2s;
  margin: 0 20px;
  cursor: pointer;
}

.view-button.view-btn-primary {
  color: #333;
  background-color: rgb(230, 230, 230);
  /* box-shadow: -4px -2px 16px 0px #ffffff, 4px 2px 16px 0px rgb(95 157 231 / 48%); */
}

.view-button.view-btn-secondary {
  color: #fff;
  background-color: rgb(50, 50, 50);
  /* box-shadow: -4px -2px 16px 0px #ffffff, 4px 2px 16px 0px rgb(95 157 231 / 48%); */
}

.view-button.view-btn-primary:hover {
  color: #333;
  background-color: rgb(200, 200, 200);
  /* box-shadow: -2px -1px 8px 0px #ffffff, 2px 1px 8px 0px rgb(95 157 231 / 48%); */
}

.view-button.view-btn-secondary:hover {
  color: #ededed;
  background-color: rgb(20, 20, 20);
  /* box-shadow: -2px -1px 8px 0px #ffffff, 2px 1px 8px 0px rgb(64, 70, 96 / 48%); */
}

.view-button:active {
  box-shadow: none;
}

/* BOTÃO APAGAR */
.btn__tooltip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: .5em;
  border: 0px solid transparent;
  background-color: rgba(237, 77, 77, 0.08);
  border-radius: .5em;
  transition: all 0.2s linear;
}
.btn__tooltip.btn__simple__clear {
  background-color: rgba(237, 77, 77, 0.08);
  border: 1px solid rgba(237, 77, 77, 0.25);
}
.btn__tooltip.btn__simple__save {
  background-color: rgba(77, 237, 90, 0.08);
  border: 1px solid rgba(77, 237, 90, 0.25);
}
.btn__tooltip.btn__simple__reload {
  background-color: rgba(77, 77, 77, 0.08);
  border: 1px solid rgba(77, 77, 77, 0.25);
}

.btn__tooltip.btn__simple__clear:active {
  background-color: rgba(237, 77, 77, 0.25);
}
.btn__tooltip.btn__simple__save:active {
  background-color: rgba(77, 237, 90, 0.25);
}
.btn__tooltip.btn__simple__reload:active {
  background-color: rgba(77, 77, 77, 0.25);
}

.btn__tooltip:hover {
  box-shadow: 3.4px 2.5px 4.9px rgba(0, 0, 0, 0.025),
    8.6px 6.3px 12.4px rgba(0, 0, 0, 0.035),
    17.5px 12.8px 25.3px rgba(0, 0, 0, 0.045),
    36.1px 26.3px 52.2px rgba(0, 0, 0, 0.055),
    99px 72px 143px rgba(0, 0, 0, 0.08);
}

.btn__tooltip {
  position: relative;
  display: inline-block;
}

.btn__tooltip .btn__tooltiptext {
  visibility: hidden;
  width: 4em;
  background-color: rgba(0, 0, 0, 0.753);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 25%;
  left: 110%;
  font-size: 1em;
}

.btn__tooltip .btn__tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent rgba(0, 0, 0, 0.753) transparent transparent;
}

.btn__tooltip:not(:disabled):hover .btn__tooltiptext {
  visibility: visible;
}


/* BOTÃO DE PAGAMENTO */
/* only animate if the device supports hover */
@media (hover: hover) {
  #creditcard {
    /*  set start position */
    transform: translateY(110px);
    transition: transform 0.1s ease-in-out;
    /*  set transition for mouse enter & exit */
  }

  #money {
    /*  set start position */
    transform: translateY(180px);
    transition: transform 0.1s ease-in-out;
    /*  set transition for mouse enter & exit */
  }

  button:hover #creditcard {
    transform: translateY(0px);
    transition: transform 0.2s ease-in-out;
    /*  overide transition for mouse enter */
  }

  button:hover #money {
    transform: translateY(0px);
    transition: transform 0.3s ease-in-out;
    /*  overide transition for mouse enter */
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-0.25rem);
  }
  100% {
    transform: translateY(0);
  }
}

.button__payment:hover .button__payment__text span {
  transform: translateY(-0.25rem);
  transition: transform .2s ease-in-out;
}

/* styling */
.button__payment {
  border: none;
  outline: none;
  padding: .315rem 60px .315rem 1rem;
  position: relative;
  border-radius: 50px;
  letter-spacing: 0.7px;
  background-color: #006eff;
  color: #fff;
  font-size: 21px;
  font-family: "Lato", sans-serif;
  cursor: pointer;
  /* box-shadow: rgba(0, 9, 61, 0.2) 0px 4px 8px 0px; */
}

.button__payment:active {
  background-color: #0060de;
}

.button__payment__svg {
  position: absolute;
  overflow: visible;
  bottom: 6px;
  right: 0.2rem;
  height: 140%;
}
